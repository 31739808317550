const userKey = '_servicos_user';
const INITIAL_STATE = {
	usuario: JSON.parse(localStorage.getItem(userKey)),
	validToken: false
}
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'TOKEN_VALIDATED':
			if (action.payload) {
				return { ...state, validToken: true }
			} else {
				localStorage.removeItem(userKey)
				return { ...state, validToken: false, usuario: null }
			}
			case 'USER_FETCHED':
				localStorage.setItem(userKey, JSON.stringify(action.payload))
				return { ...state, usuario: action.payload, validToken: true }
				default:
			return state;
	}
}
