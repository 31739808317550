import * as moment from 'moment';
import 'moment/locale/pt-br';

export function getDataAtual() {

	let data = new Date();
	if (moment(new Date()).format('Z') == '-02:00') {
		data.setHours(data.getHours() - 1);
	}

	return moment(data).format('YYYY/MM/DD HH:mm:ss');

}

export function getData(data) {

	data = new Date(data);
	if (moment(new Date()).format('Z') == '-03:00') {
		data.setHours(data.getHours() + 1);
	}

	return data;
}

export function getDataFormatada(data, formato = 'YYYY/MM/DD HH:mm:ss') {

	data = new Date(data);
	if (moment(new Date()).format('Z') == '-03:00') {
		data.setHours(data.getHours() + 1);
	}

	return moment(data).format(formato);
}
