import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import cookie from 'react-cookies'
import { getLista, getListaCliente, getListaUnidade } from '../relatorioDemanda/relatorioDemandaActions';

import Select from '../common/form/select';

class RelatorioDemandaLista extends Component {

    componentWillMount() {
		this.props.getLista(cookie.load('relatorioDemandaListaUnidade') || 0);
		this.props.getListaCliente();
		this.props.getListaUnidade(cookie.load('relatorioDemandaListaCliente') || 0);
    }

	renderCabecalho() {
        const lista = this.props.lista || [];
		if (lista.length > 0 && lista[0].dados && lista[0].dados.length > 0) {
			return lista[0].dados.map(item => (
	            <th colspan={2} style={{ textAlign: 'center' }} key={item}>{item}</th>
	        ));
		} else {
			return null;
		}
    }

	renderCabecalhoItem() {
        const lista = this.props.lista || [];
		if (lista.length > 0 && lista[0].dados && lista[0].dados.length > 0) {
			let listaLidaContratada = [];
			lista[0].dados.forEach(itemAno => {
				listaLidaContratada.push('Lida');
				listaLidaContratada.push('Contratada');
			});

			return listaLidaContratada.map(item => (
				<th width={100} style={{ textAlign: 'center' }} key={item}>{item}</th>
	        ));
		} else {
			return null;
		}
    }

    renderRows() {
        const lista = this.props.lista || [];
		if (lista.length > 0) {
			let cabecalho = true;
	        return lista.map(item => {
				if (cabecalho) {
					cabecalho = false;
					return null;
				}
				let keyAno = 0;
				if (item.dados && item.dados.length > 0) {

					let listaLidaContratada = [];
					item.dados.forEach(itemAno => {
						listaLidaContratada.push({
							valor: itemAno.valor_fatura,
							aniversario: itemAno.aniversario,
							tipo: 'fatura'
						});
						listaLidaContratada.push({
							valor: itemAno.valor_demanda,
							tipo: 'demanda',
							valorFatura: itemAno.valor_fatura,
							aniversario: itemAno.aniversario,
							abaixo: parseFloat(itemAno.valor_demanda) > parseFloat(itemAno.valor_fatura)
						});
					});

					return (
			            <tr key={item.mes}>
			                <td>{item.mes.charAt(0).toUpperCase() + item.mes.slice(1)}</td>
							{listaLidaContratada.map(lidaContradada => {
								keyAno++;
								let style = {
									textAlign: 'right'
								}
								if (lidaContradada.aniversario) {
									style.backgroundColor = '#f4ff81';
								}
								if (lidaContradada.valor > 0 && lidaContradada.valorFatura > 0) {
									style.backgroundColor = lidaContradada.abaixo ? '#f44336' : '#43a047';
									style.color = '#FFFFFF';
								}
								console.log(item.mes);
								console.log(lidaContradada);

								return (
									<td key={keyAno} style={style}>{parseFloat(lidaContradada.valor || 0).toFixed(2).replace('.', ',')}</td>
								);
							})}
			            </tr>
					);
				} else {
					return null;
				}
	        });
		} else {
			return null;
		}
    }

    render() {
		const lista = this.props.lista || [];
        return (
            <div>
				<form role='form' onSubmit={() => null}>
					<div className='box-body'>
						<div className='row'>
							<div className='col-sm-12'>
								<Field name='id_cliente' component={Select}
									onChange={(e) => {
										this.props.getLista(0);
										cookie.save('relatorioDemandaListaCliente', e.target.value, { path: '/' });
										this.props.getListaUnidade(e.target.value);
									}}
									value={cookie.load('relatorioDemandaListaCliente') || 0}
									options={this.props.listaCliente}
									label='Cliente' cols='12 6' placeholder='Selecione o Cliente' />
							</div>
							<div className='col-sm-12'>
								<Field name='id_unidade' component={Select}
									onChange={(e) => {
										cookie.save('relatorioDemandaListaUnidade', e.target.value, { path: '/' });
										this.props.getLista(e.target.value);
									}}
									value={cookie.load('relatorioDemandaListaUnidade') || 0}
									options={this.props.listaUnidade}
									label='Unidade' cols='12 6' placeholder='Selecione a Unidade' />
							</div>
						</div>
					</div>
				</form>
                <table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
                            <th rowspan={2}>Mês</th>
							{this.renderCabecalho()}
                        </tr>
						<tr>
							{this.renderCabecalhoItem()}
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

RelatorioDemandaLista = reduxForm({form: 'relatorioDemandaLista', destroyOnUnmount: false})(RelatorioDemandaLista);
const mapStateToProps = state => ({
	lista: state.relatorioDemanda.lista,
	listaCliente: state.relatorioDemanda.listaCliente,
	listaUnidade: state.relatorioDemanda.listaUnidade
});
const mapDispatchToProps = dispatch => bindActionCreators({ getLista, getListaCliente, getListaUnidade }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RelatorioDemandaLista);
