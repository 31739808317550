const INITIAL_STATE = {
    lista: [], 
    listaCliente: [], 
    listaUnidade: [], 
    clienteSelecionado: 0 
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SIMULACAO_FATURA_LISTADA':
            return { ...state, lista: action.payload.data };

		case 'SIMULACAO_FATURA_CLIENTE_LISTADO':
            return { ...state, listaCliente: action.payload.data };

		case 'SIMULACAO_FATURA_UNIDADE_LISTADA':
            return { ...state, listaUnidade: action.payload.data };

        default:
            return state;
    }
}
