import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import Tabs from '../common/tab/tabs';
import TabsHeader from '../common/tab/tabsHeader';
import TabsContent from '../common/tab/tabsContent';
import TabHeader from '../common/tab/tabHeader';
import TabContent from '../common/tab/tabContent';
import FormatarValor from '../common/form/formatarValor';
import Select from '../common/form/select';

import { modoLista, getListaUnidade, getLista } from './simuladorFaturaActions';

class SimuladorFatura extends Component {

    state = {
        id_cliente: null,
        id_unidade: null,
        lista: []
    }

    componentWillMount() {
        this.props.modoLista();
    }

    adicionar(registro) {

        let parametro = (this.props.lista || [])[0];
        let tarifas = parametro ? (parametro.tarifas || []).filter(item => item) : [];
        let cargas = parametro ? (parametro.cargas || []).filter(item => item) : [];

        let listaHorario = [];
		let dataHoraBase = new Date('2023/01/01 00:00');
		for (let i = 0; i < 24; i++) {

			listaHorario.push({
                posicao: i,
				id: `${String(dataHoraBase.getHours()).padStart(2, '0')}:${String(dataHoraBase.getMinutes()).padStart(2, '0')}`,
                valor: `${String(dataHoraBase.getHours()).padStart(2, '0')}:${String(dataHoraBase.getMinutes()).padStart(2, '0')}`
			});
			
			dataHoraBase.setMinutes(dataHoraBase.getMinutes() + 60);
        }

        let carga = cargas.filter(carga => carga.id == registro.id_carga)[0];
        let inicio = listaHorario.filter(inicio => inicio.id == registro.horario_inicio)[0];
        let fim = listaHorario.filter(fim => fim.id == registro.horario_fim)[0];
        let dataHoraInicio = new Date(`2023/01/01 ${inicio.valor}`);
        let dataHoraFim = inicio.posicao < fim.posicao ? new Date(`2023/01/01 ${fim.valor}`) : new Date(`2023/01/02 ${fim.valor}`);
        
        let quantidade = (parseInt(dataHoraFim.getTime() - dataHoraInicio.getTime()) / 1000 / 60 / 60) * carga.carga;
        
        let unidade = this.props.listaUnidade.filter(unidade => unidade.id == this.state.id_unidade)[0];
        
        let tarifaReservado = tarifas.filter(itemTarifa => itemTarifa.descricao.includes('ENERGIA RESERVADO'))[0];
        let tarifaPonta = tarifas.filter(itemTarifa => itemTarifa.descricao.includes('ENERGIA PONTA'))[0];
        let tarifaForaPonta = tarifas.filter(itemTarifa => itemTarifa.descricao.includes('ENERGIA FORA PONTA'))[0];
        
        let tipo = 'FORA PONTA';
        let tarifa = tarifaForaPonta ? tarifaForaPonta.valor_unitario || 0 : 0;
        
        if (/*unidade.periodo_reservado_inicio && unidade.periodo_reservado_fim*/
            tarifaReservado
            && ['21:00', '22:00','23:00', '00:00', '01:00', '02:00', '04:00', '05:00']
            .filter(horario => horario == inicio.valor).length) {
            tarifa = tarifaReservado.valor_unitario || 0;
            tipo = 'RESERVADO';
        } else if (/*unidade.periodo_ponta_inicio && unidade.periodo_ponta_fim*/
            tarifaPonta
            && ['18:00', '19:00','20:00']
            .filter(horario => horario == inicio.valor).length) {
                tarifa = tarifaPonta.valor_unitario || 0;
                tipo = 'PONTA';
        } else if (/*unidade.periodo_fora_ponta_inicio && unidade.periodo_fora_ponta_fim*/
            tarifaForaPonta
            && ['21:00', '22:00','23:00', '00:00', '01:00', '02:00', '04:00', '05:00', '06:00', '07:00','08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00']
            .filter(horario => horario == inicio.valor).length) {
            tarifa = tarifaForaPonta.valor_unitario || 0;
            tipo = 'FORA PONTA';
        }
        
        let total = parseFloat((quantidade * tarifa).toFixed(2));

        let lista = this.state.lista.map(item => item);
        
        lista.push({
            ...registro,
            nome_carga: carga.nome,
            tipo: tipo,
            quantidade: quantidade,
            tarifa: tarifa,
            valor_total: total
        });
        this.setState({ 
            ...this.state,
            lista: lista
        });
                                                        
    }

    render() {

        let parametro = (this.props.lista || [])[0];
        let tarifas = parametro ? (parametro.tarifas || []).filter(item => item) : [];
        let cargas = parametro ? (parametro.cargas || []).filter(item => item) : [];

        let listaHorario = [];
		let dataHoraBase = new Date('2023/01/01 00:00');
		for (let i = 0; i < 24; i++) {

			listaHorario.push({
                posicao: i,
				id: `${String(dataHoraBase.getHours()).padStart(2, '0')}:${String(dataHoraBase.getMinutes()).padStart(2, '0')}`,
                valor: `${String(dataHoraBase.getHours()).padStart(2, '0')}:${String(dataHoraBase.getMinutes()).padStart(2, '0')}`
			});
			
			dataHoraBase.setMinutes(dataHoraBase.getMinutes() + 60);
        }

        let valorTotal = 0;

        let listaAgrupador = [];
        (this.state.lista || []).forEach(item => {
            if (listaAgrupador.filter(agrupado => agrupado.tipo == item.tipo)[0]) {
                listaAgrupador = listaAgrupador.map(agrupador => {
                    if (agrupador.tipo == item.tipo) {
                        return {
                            ...agrupador,
                            quantidade: agrupador.quantidade + item.quantidade,
                            valor_total: agrupador.valor_total + item.valor_total
                        };
                    } else {
                        return agrupador;
                    }
                });
            } else {
                listaAgrupador.push({
                    ...item
                });
            }

        });
        
        return (
            <div>
                <ContentHeader title='Simulador de Fatura' small='Cadastro' />
                <Content>
                    <Tabs>
                        <TabsContent>
                            <TabContent id='modoLista'>
                                <div className='box-body'>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <Select name='id_cliente'
                                                onChange={(e) => {                                               
                                                
                                                    this.setState({ 
                                                        ...this.state, 
                                                        id_cliente: e.target.value, 
                                                        lista: [],
                                                        id_carga: this.state.id_carga,
                                                        horario_inicio: this.state.horario_inicio,
                                                        horario_fim: this.state.horario_fim 
                                                    });
                                                    this.props.getListaUnidade(e.target.value);
                                                    this.props.getLista(0);
                                                }}
                                                value={this.state.id_cliente}
                                                options={this.props.listaCliente}
                                                label='Cliente' cols='12 12' placeholder='Selecione o Cliente' />
                                        </div>
                                        <div className='col-sm-6'>
                                            <Select name='id_unidade'
                                                onChange={(e) => {

                                                    this.setState({ 
                                                        ...this.state, 
                                                        id_unidade: e.target.value, 
                                                        lista: [],
                                                        id_carga: this.state.id_carga,
                                                        horario_inicio: this.state.horario_inicio,
                                                        horario_fim: this.state.horario_fim 
                                                    });
                                                    this.props.getLista(e.target.value || 0);
                                                }}
                                                value={this.state.id_unidade}
                                                options={this.props.listaUnidade}
                                                label='Unidade' cols='12 12' placeholder='Selecione a Unidade' />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        {parametro &&
                                        <div>
                                            <table className='table table-bordered table-striped dataTable'>
                                                <thead>
                                                    <tr>
                                                        <th>Tarifas</th>
                                                        <th width={200} style={{ textAlign: 'right' }}>Valor Unitário</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tarifas.map(item => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td>{item.descricao}</td>
                                                                <td align='right'>
                                                                    R$ <FormatarValor valor={item.valor_unitario} displayType={'text'} casas={4} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>}
                                    </div>
                                    <div className='col-sm-6'>
                                        {parametro &&
                                        <div>
                                            <table className='table table-bordered table-striped dataTable'>
                                                <thead>
                                                    <tr>
                                                        <th>Cargas</th>
                                                        <th width={200} style={{ textAlign: 'right' }}>kW</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {cargas.map(item => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td>{item.nome}</td>
                                                                <td align='right'>
                                                                    <FormatarValor valor={item.carga} displayType={'text'} casas={0} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>}
                                    </div>
                                </div>
                                {parametro &&
                                <div className='row'>							
                                    <Select name='id_carga'
                                        onChange={(e) => {
                                            this.setState({ ...this.state, id_carga: e.target.value });
                                        }}
                                        value={this.state.id_carga}
                                        options={parametro ? parametro.cargas.map(item => ({ id: item.id, valor: item.nome })) : []}
                                        label='Carga' cols='12 3' placeholder='Selecione a carga' />

                                    <Select name='horario_inicio'
                                        onChange={(e) => {
                                            this.setState({ ...this.state, horario_inicio: e.target.value });
                                        }}
                                        value={this.state.horario_inicio}
                                        options={listaHorario}
                                        label='Ligou' cols='12 3' placeholder='Selecione o horário' />
                                        
                                    <Select name='horario_fim'
                                        onChange={(e) => {
                                            this.setState({ ...this.state, horario_fim: e.target.value });
                                        }}
                                        value={this.state.horario_fim}
                                        options={listaHorario}
                                        label='Desligou' cols='12 3' placeholder='Selecione o horário' />
                                
                                    {(this.state.id_carga && this.state.horario_inicio && this.state.horario_fim) &&
                                    <div className='col-sm-3'>
                                        <button className='btn btn-success' 
                                            style={{ marginTop: 25 }}
                                            onClick={() => {

                                                this.adicionar({
                                                    id: new Date().getTime(),
                                                    id_carga: this.state.id_carga,
                                                    horario_inicio: this.state.horario_inicio,
                                                    horario_fim: this.state.horario_fim
                                                });
                                                
                                            }} >
                                            <i className='fa fa-plus'> Adicionar</i>
                                        </button>
                                    </div>}
                                    <div className='col-sm-12'>
                                        <div>
                                            <table className='table table-bordered table-striped dataTable'>
                                                <thead>
                                                    <tr>
                                                        <th>Carga</th>
                                                        <th style={{ textAlign: 'center' }}>Ligou</th>
                                                        <th style={{ textAlign: 'center' }}>Desligou</th>
                                                        <th style={{ textAlign: 'center' }}>Horário</th>
                                                        <th style={{ textAlign: 'center' }}>Grandeza</th>
                                                        <th style={{ textAlign: 'center' }}>Valor Unitário</th>
                                                        <th style={{ textAlign: 'center' }}>Quantidade</th>
                                                        <th style={{ textAlign: 'right' }}>Valor Total</th>
                                                        <th style={{ textAlign: 'right' }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.lista.map(item => {
                                                        
                                                        valorTotal += item.valor_total;

                                                        return (
                                                            <tr key={item.id}>
                                                                <td>{item.nome_carga}</td>
                                                                <td style={{ textAlign: 'center' }}>{item.horario_inicio}</td>
                                                                <td style={{ textAlign: 'center' }}>{item.horario_fim}</td>
                                                                <td style={{ textAlign: 'center' }}>{item.tipo}</td>
                                                                <td align='center'>KW/h</td>
                                                                <td align='center'>
                                                                    <FormatarValor valor={item.tarifa} displayType={'text'} casas={4} />
                                                                </td>
                                                                <td align='center'>
                                                                    <FormatarValor valor={item.quantidade} displayType={'text'} casas={0} />
                                                                </td>
                                                                <td align='right'>
                                                                    <FormatarValor valor={item.valor_total} displayType={'text'} casas={2} />
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-danger' onClick={() => this.setState({ ...this.state, lista: this.state.lista.filter(itemLista => itemLista.id != item.id) })} >
                                                                        <i className='fa fa-trash-o'></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td colSpan='6' style={{ fontWeight: 'bold' }}>Total</td>
                                                        <td align='right' style={{ fontWeight: 'bold' }}>
                                                            <FormatarValor valor={valorTotal} displayType={'text'} casas={2} />
                                                        </td>
                                                        <td>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className='col-sm-12'>
                                        <h3 style={{ textAlign: 'center' }}>Resumo da Simulação</h3>
                                    </div>

                                    <div className='col-sm-12'>
                                        <div>
                                            <table className='table table-bordered table-striped dataTable'>
                                                <thead>
                                                    <tr>
                                                        <th>Produto</th>
                                                        <th style={{ textAlign: 'center' }}>Grandeza</th>
                                                        <th style={{ textAlign: 'center' }}>Vlr Unitário</th>
                                                        <th style={{ textAlign: 'center' }}>Quantidade</th>
                                                        <th style={{ textAlign: 'right' }}>Valor Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listaAgrupador.map(item => {
                                                        
                                                        valorTotal += item.valor_total;

                                                        return (
                                                            <tr key={item.tipo}>
                                                                <td>CONSUMO DE ENERGIA {item.tipo}</td>
                                                                <td align='center'>KW/h</td>
                                                                <td align='center'>
                                                                    <FormatarValor valor={item.tarifa} displayType={'text'} casas={4} />
                                                                </td>
                                                                <td align='center'>
                                                                    <FormatarValor valor={item.quantidade} displayType={'text'} casas={0} />
                                                                </td>
                                                                <td align='right'>
                                                                    <FormatarValor valor={item.valor_total} displayType={'text'} casas={2} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td colSpan='4' style={{ fontWeight: 'bold' }}>Total</td>
                                                        <td align='right' style={{ fontWeight: 'bold' }}>
                                                            <FormatarValor valor={valorTotal} displayType={'text'} casas={2} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}
                            </TabContent>
                        </TabsContent>
                    </Tabs>
                </Content>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    lista: state.simuladorFatura.lista,
	listaCliente: state.simuladorFatura.listaCliente,
	listaUnidade: state.simuladorFatura.listaUnidade
});

const mapDispatchToProps = dispatch => bindActionCreators({ modoLista, getListaUnidade, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SimuladorFatura);
