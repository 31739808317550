import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import { modoLista } from './produtoActions';
import LabelAndInput from '../common/form/labelAndInput';
import Grid from '../common/layout/grid';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';

import { getListaTipoProduto, changeValueCompoeGraficoConsumo, changeValueCompoeDemandaLida, changeValueCompoeGeracaoEnergia } from './produtoActions';

class ProdutoForm extends Component {

    componentWillMount() {
        this.props.getListaTipoProduto();
    }

    render() {
        const { handleSubmit, readOnly } = this.props;
        const listaTipoProduto = this.props.listaTipoProduto || [];
        return (
            <form role='form' onSubmit={handleSubmit}>
                <div className='box-body'>
                    <Field name='descricao' component={LabelAndInput} readOnly={readOnly}
                        label='Descrição' cols='12 12' placeholder='Informe a Descrição' />

					<Field name='id_tipo_produto' component={Select}
                        onChange={this.props.changeValueTipoProduto}
                        value={this.props.tipoProdutoSelecionado}
                        options={listaTipoProduto} readOnly={readOnly}
                        label='Tipo de Produto' cols='12 4' placeholder='Selecione o Tipo de Produto' />

					<Field name='compoe_grafico_consumo' component={Select}
						onChange={this.props.changeValueCompoeGraficoConsumo}
						value={this.props.tipoProdutoSelecionado ? 1 : 2}
						options={[ { id: 1, valor: 'Sim'}, { id: 2, valor: 'Não'} ]} readOnly={readOnly}
						label='Compõe Gráfico Consumo' cols='12 4' placeholder='Selecione se o produto compõe o gráfico de consumo' />

					<Field name='compoe_demanda_lida' component={Select}
						onChange={this.props.changeValueCompoeDemandaLida}
						value={this.props.compoeDemandaLidaSelecionado ? 1 : 2}
						options={[ { id: 1, valor: 'Sim'}, { id: 2, valor: 'Não'} ]} readOnly={readOnly}
						label='Compõe Demanda Lida' cols='12 4' placeholder='Selecione se o produto compõe a demanda lida' />

					<Field name='compoe_geracao_energia' component={Select}
						onChange={this.props.changeValueCompoeGeracaoEnergia}
						value={this.props.compoeGeracaoEnergiaSelecionado ? 1 : 2}
						options={[ { id: 1, valor: 'Sim'}, { id: 2, valor: 'Não'} ]} readOnly={readOnly}
						label='Compõe Geração de Energia' cols='12 4' placeholder='Selecione se o produto compõe a geração de energia' />

                </div>
                <div className='box-footer'>
                    <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                        {this.props.submitLabel}
                    </button>
                    <button type='button' className='btn btn-default'
                        onClick={this.props.modoLista}>Cancelar</button>
                </div>
            </form>
        );
    }
}

ProdutoForm = reduxForm({form: 'produtoForm', destroyOnUnmount: false})(ProdutoForm);
const mapStateToProps = state => ({
    listaTipoProduto: state.produto.listaTipoProduto,
    tipoProdutoSelecionado: state.produto.tipoProdutoSelecionado,
	compoeGraficoConsumoSelecionado: state.produto.compoeGraficoConsumoSelecionado,
	compoeDemandaLidaSelecionado: state.produto.compoeDemandaLidaSelecionado,
	compoeGeracaoEnergiaSelecionado: state.produto.compoeGeracaoEnergiaSelecionado
});
const mapDispatchToProps = dispatch => bindActionCreators({ modoLista, getListaTipoProduto, changeValueCompoeGraficoConsumo, changeValueCompoeDemandaLida, changeValueCompoeGeracaoEnergia }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProdutoForm);
