const INITIAL_STATE = {lista: [], listaDemandaItem: [], listaCliente: [], listaUnidade: [], clienteSelecionado: 0 };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'DEMANDA_LISTADA':
            return { ...state, lista: action.payload.data };
		case 'DEMANDA_ITEM_LISTADA':
			return { ...state, listaDemandaItem: action.payload.data };
		case 'DEMANDA_CLIENTE_LISTADO':
            return { ...state, listaCliente: action.payload.data };
		case 'DEMANDA_UNIDADE_LISTADA':
            return { ...state, listaUnidade: action.payload.data };
		case 'CLIENTE_VALUE_CHANGED':
            return { ...state, clienteSelecionado: action.payload };
        default:
            return state;
    }
}
