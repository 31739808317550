import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import cookie from 'react-cookies'
import { getLista, getListaCliente, getListaUnidade, modoAlteracao, modoExclusao, modoDemandaItemInclusao } from './demandaActions';

import Select from '../common/form/select';

class DemandaLista extends Component {

    componentWillMount() {
		this.props.getLista(cookie.load('demandaListaUnidade') || 0);
		this.props.getListaCliente();
		this.props.getListaUnidade(cookie.load('demandaListaCliente') || 0);
    }

    renderRows() {
        const lista = this.props.lista || [];
        return lista.map(demanda => (
            <tr key={demanda.id}>
                <td>{demanda.nome_cliente}</td>
				<td>{demanda.nome_unidade}</td>
				<td>{demanda.competencia_inicio_formatada.charAt(0).toUpperCase() + demanda.competencia_inicio_formatada.slice(1)}</td>
				<td>{demanda.competencia_fim_formatada.charAt(0).toUpperCase() + demanda.competencia_fim_formatada.slice(1)}</td>
				<td>{demanda.competencia_aniversario_formatada.charAt(0).toUpperCase() + demanda.competencia_aniversario_formatada.slice(1)}</td>
                <td>
                    <button className='btn btn-warning' onClick={() => this.props.modoAlteracao(demanda)}>
                        <i className='fa fa-pencil'></i>
                    </button>
					<button className='btn btn-success' onClick={() => this.props.modoDemandaItemInclusao(
						{
							id_demanda: demanda.id,
							id_unidade: demanda.id_unidade,
							id_cliente: demanda.id_cliente,
							nome_unidade: demanda.nome_unidade,
							nome_cliente: demanda.nome_cliente,
							competencia_inicio: demanda.competencia_inicio,
							competencia_fim: demanda.competencia_fim,
							competencia_aniversario: demanda.competencia_aniversario
						})}>
                        <i className='fa fa-reorder'></i>
                    </button>
                    <button className='btn btn-danger' onClick={() => this.props.modoExclusao(demanda)}>
                        <i className='fa fa-trash-o'></i>
                    </button>
                </td>
            </tr>
        ));
    }

    render() {
        return (
            <div>
				<form role='form' onSubmit={() => null}>
					<div className='box-body'>
						<div className='row'>
							<div className='col-sm-12'>
								<Field name='id_cliente' component={Select}
									onChange={(e) => {
										this.props.getLista(0);
										cookie.save('demandaListaCliente', e.target.value, { path: '/' });
										this.props.getListaUnidade(e.target.value);
									}}
									value={cookie.load('demandaListaCliente')}
									options={this.props.listaCliente}
									label='Cliente' cols='12 6' placeholder='Selecione o Cliente' />
							</div>
							<div className='col-sm-12'>
								<Field name='id_unidade' component={Select}
									onChange={(e) => {
										cookie.save('demandaListaUnidade', e.target.value, { path: '/' });
										this.props.getLista(e.target.value);
									}}
									value={cookie.load('demandaListaUnidade')}
									options={this.props.listaUnidade}
									label='Unidade' cols='12 6' placeholder='Selecione a Unidade' />
							</div>
						</div>
					</div>
				</form>
                <table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
                            <th>Cliente</th>
							<th>Unidade</th>
							<th>Início</th>
							<th>Fim</th>
							<th>Aniversário</th>
                            <th className='table-actions'>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

DemandaLista = reduxForm({form: 'demandaLista', destroyOnUnmount: false})(DemandaLista);
const mapStateToProps = state => ({
	lista: state.demanda.lista,
	listaCliente: state.demanda.listaCliente,
	listaUnidade: state.demanda.listaUnidade
});
const mapDispatchToProps = dispatch => bindActionCreators({getLista, getListaCliente, getListaUnidade, modoAlteracao, modoExclusao, modoDemandaItemInclusao}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DemandaLista);
