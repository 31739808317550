const INITIAL_STATE = {lista: [], listaCliente: [], listaUnidade: [], clienteSelecionado: 0 };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'UNIDADE_CARGA_LISTADA':
            return { ...state, lista: action.payload.data };
		case 'UNIDADE_CARGA_CLIENTE_LISTADO':
            return { ...state, listaCliente: action.payload.data };
		case 'UNIDADE_CARGA_UNIDADE_LISTADA':
            return { ...state, listaUnidade: action.payload.data };
		case 'CLIENTE_VALUE_CHANGED':
            return { ...state, clienteSelecionado: action.payload };
        default:
            return state;
    }
}
