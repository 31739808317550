import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modoAlteracao, modoExclusao, modoUnidadeAlteracao, removerUnidade } from './clienteActions';

class ClienteListaUnidades extends Component {

    renderRows() {
        const lista = this.props.listaUnidade || [];
        return lista.map(unidade => (
            <tr key={unidade.id}>
				<td>{unidade.uc}</td>
				<td>{unidade.nome_fantasia}</td>
				<td>{unidade.cpf_cnpj}</td>
				<td>{unidade.logradouro}</td>
				<td>{unidade.numero}</td>
				<td>{unidade.complemento}</td>
				<td>{unidade.bairro}</td>
				<td>{unidade.cep}</td>
				<td>{unidade.descricao_cidade}</td>
                <td>
					<button type='button' className='btn btn-warning' onClick={() => this.props.modoUnidadeAlteracao(unidade)}>
						<i className='fa fa-pencil'></i>
					</button>
					<button type='button' className='btn btn-danger' onClick={() => this.props.removerUnidade(unidade)}>
                        <i className='fa fa-trash-o'></i>
                    </button>
                </td>
            </tr>
        ));
    }

    render() {
        return (
            <div>
                <table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
							<th>UC</th>
							<th>Nome Fantasia</th>
							<th>CPF/CNPJ</th>
							<th>Logradouro</th>
							<th>Número</th>
							<th>Complemento</th>
							<th>Bairro</th>
							<th>CEP</th>
							<th>Cidade</th>
                            <th className='table-actions'>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => ({listaUnidade: state.cliente.listaUnidade});
const mapDispatchToProps = dispatch => bindActionCreators({ modoAlteracao, modoExclusao, modoUnidadeAlteracao, removerUnidade}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClienteListaUnidades);
