import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';

const INITIAL_VALUES = { nome: '' };

export function getLista(id_unidade) {
    const request = axios.get(`${consts.API_URL}/unidadeCarga?id_unidade=${id_unidade}`);
    return {
        type: 'UNIDADE_CARGA_LISTADA',
        payload: request
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarselect`);
    return {
        type: 'UNIDADE_CARGA_CLIENTE_LISTADO',
        payload: request
    };
}

export function getListaUnidade(id_cliente) {
    const request = axios.get(`${consts.API_URL}/unidade/listarselect?id_cliente=${id_cliente}`);
    return {
        type: 'UNIDADE_CARGA_UNIDADE_LISTADA',
        payload: request
    };
}

export function incluir(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/unidadeCarga`, registro)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function alterar(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/unidadeCarga`, registro)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/unidadeCarga?id=${registro.id}`)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function modoLista() {
    return [
        showTabs('modoLista'),
        selectTab('modoLista'),
        initialize('unidadeCargaForm', INITIAL_VALUES)
    ];
}

export function initForm(unidadeCarga) {
    return [
        initialize('unidadeCargaForm', unidadeCarga)
    ];
}

export function modoInclusao(unidadeCarga) {
    return [
        showTabs('modoInclusao'),
        selectTab('modoInclusao'),
		getListaCliente(),
		getListaUnidade(unidadeCarga ? unidadeCarga.id_cliente : 0),
        initialize('unidadeCargaForm', unidadeCarga)
    ];
}

export function modoAlteracao(unidadeCarga) {
    return [
        showTabs('modoAlteracao'),
        selectTab('modoAlteracao'),
		getListaCliente(),
		getListaUnidade(unidadeCarga.id_cliente),
        initialize('unidadeCargaForm', unidadeCarga)
    ];
}

export function modoExclusao(unidadeCarga) {
    return [
        showTabs('modoExclusao'),
        selectTab('modoExclusao'),
		getListaCliente(),
		getListaUnidade(unidadeCarga.id_cliente),
        initialize('unidadeCargaForm', unidadeCarga)
    ];
}

