const INITIAL_STATE = { lista: [], listaCliente: [], listaUnidade: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'RELATORIO_DEMANDA_LISTADO':
            return { ...state, lista: action.payload.data };
		case 'RELATORIO_DEMANDA_CLIENTE_LISTADO':
            return { ...state, listaCliente: action.payload.data };
		case 'RELATORIO_DEMANDA_UNIDADE_LISTADA':
            return { ...state, listaUnidade: action.payload.data };
        default:
            return state;
    }
}
