import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import cookie from 'react-cookies'
import { getLista, getListaCliente, getListaUnidade } from '../relatorioFatura/relatorioFaturaActions';

import Select from '../common/form/select';


class RelatorioFaturaLista extends Component {

    componentWillMount() {
		this.props.getLista(cookie.load('relatorioFaturaListaUnidade') || 0);
		this.props.getListaCliente();
		this.props.getListaUnidade(cookie.load('relatorioFaturaListaCliente') || 0);
    }

	renderCabecalho() {
        const lista = this.props.lista || [];
		if (lista.length > 0 && lista[0].dados && lista[0].dados.length > 0) {
			return lista[0].dados.map(item => (
	            <th width={100} style={{ textAlign: 'right' }} key={item}>{item}</th>
	        ));
		} else {
			return null;
		}
    }

    renderRows() {
        const lista = this.props.lista || [];
		if (lista.length > 0) {
			let cabecalho = true;
	        return lista.map(item => {
				if (cabecalho) {
					cabecalho = false;
					return null;
				}
				let keyAno = 0;
				if (item.dados && item.dados.length > 0) {
					return (
			            <tr key={item.mes}>
			                <td>{item.mes.charAt(0).toUpperCase() + item.mes.slice(1)}</td>
							{item.dados.map(valorAno => {
								keyAno++;
								return (
									<td style={{ textAlign: 'right' }} key={keyAno}>R$ {parseFloat(valorAno).toFixed(2).replace('.', ',')}</td>
								);
							})}
			            </tr>
					);
				} else {
					return null;
				}
	        });
		} else {
			return null;
		}
    }

    render() {
		const lista = this.props.lista || [];
        return (
            <div>
				<form role='form' onSubmit={() => null}>
					<div className='box-body'>
						<div className='row'>
							<div className='col-sm-12'>
								<Field name='id_cliente' component={Select}
									onChange={(e) => {
										this.props.getLista(0);
										cookie.save('relatorioFaturaListaCliente', e.target.value, { path: '/' });
										this.props.getListaUnidade(e.target.value);
									}}
									value={cookie.load('relatorioFaturaListaCliente') || 0}
									options={this.props.listaCliente}
									label='Cliente' cols='12 6' placeholder='Selecione o Cliente' />
							</div>
							<div className='col-sm-12'>
								<Field name='id_unidade' component={Select}
									onChange={(e) => {
										cookie.save('relatorioFaturaListaUnidade', e.target.value, { path: '/' });
										this.props.getLista(e.target.value);
									}}
									value={cookie.load('relatorioFaturaListaUnidade') || 0}
									options={this.props.listaUnidade}
									label='Unidade' cols='12 6' placeholder='Selecione a Unidade' />
							</div>
						</div>
					</div>
				</form>
                <table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
                            <th>Mês</th>
							{this.renderCabecalho()}
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

RelatorioFaturaLista = reduxForm({form: 'relatorioFaturaLista', destroyOnUnmount: false})(RelatorioFaturaLista);
const mapStateToProps = state => ({
	lista: state.relatorioFatura.lista,
	listaCliente: state.relatorioFatura.listaCliente,
	listaUnidade: state.relatorioFatura.listaUnidade
});
const mapDispatchToProps = dispatch => bindActionCreators({ getLista, getListaCliente, getListaUnidade }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RelatorioFaturaLista);
