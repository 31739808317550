import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import { modoLista } from './usuarioActions';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import { changeValueUsuarioTipo } from './usuarioActions';
import LabelAndInputPassword from '../common/form/labelAndInputPassword';

class UsuarioForm extends Component {

    state = {
        senhaVisivel: true
    }

    componentWillMount() {
        
    }

    render() {
        
        const { handleSubmit, readOnly } = this.props;
        const listaUsuarioTipo = this.props.listaUsuarioTipo || [];
        const listaCliente = this.props.listaCliente || [];
        return (
            <form role='form' onSubmit={handleSubmit}>
                <div className='box-body'>
                    <Field name='nome' component={LabelAndInput} readOnly={readOnly}
                        label='Nome' cols='12 12' placeholder='Informe o Nome' />
                    <Field name='id_usuario_tipo' component={Select}
                        options={listaUsuarioTipo} readOnly={readOnly}
                        label='Tipo de Usuário' cols='12 4' placeholder='Selecione o Tipo de Usuário' />
                    {(this.props.formularioValues && this.props.formularioValues.id_usuario_tipo == 2 /*CLIENTE*/) &&
                    <Field name='id_cliente' component={Select}
                        options={listaCliente} readOnly={readOnly}
                        label='Cliente' cols='12 4' placeholder='Selecione o cliente' />}
                    <Field name='usuario' component={LabelAndInput} readOnly={readOnly}
                        label='Usuário' cols='12 12' placeholder='Informe o Usuário' />

                    {(!this.props.formularioValues || !this.props.formularioValues.id || this.props.alterarSenha) &&
                    <Field name='senha' component={LabelAndInputPassword}
                        label='Senha' cols='4 4' placeholder='Informe a Senha'
                        type={this.state.senhaVisivel ? 'input' : 'password'}
                        icon={this.state.senhaVisivel ? 'eye-slash' : 'eye'}
                        onClickIcon={() => {
                            this.setState({ ...this.state, senhaVisivel: !this.state.senhaVisivel })
                        }} />}
                </div>
                <div className='box-footer'>
                    <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                        {this.props.submitLabel}
                    </button>
                    <button type='button' className='btn btn-default'
                        onClick={this.props.modoLista}>Cancelar</button>
                </div>
            </form>
        );
    }
}

UsuarioForm = reduxForm({form: 'usuarioForm', destroyOnUnmount: false})(UsuarioForm);
const mapStateToProps = state => ({
    formularioValues: getFormValues('usuarioForm')(state),
    listaUsuarioTipo: state.usuario.listaUsuarioTipo,
    usuarioTipoSelecionado: state.usuario.usuarioTipoSelecionado,
    listaCliente: state.usuario.listaCliente
});
const mapDispatchToProps = dispatch => bindActionCreators({ modoLista, changeValueUsuarioTipo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);
