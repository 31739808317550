import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import { modoLista, getListaUnidade } from './demandaActions';
import LabelAndInput from '../common/form/labelAndInput';
import ListaDemandaItens from './demandaListaDemandaItens';
import Select from '../common/form/select';

class DemandaForm extends Component {

    render() {
        const { handleSubmit, readOnly, listaDemandaItemTipo, listaCliente, listaUnidade } = this.props;

		if (this.props.modoDemandaItem) {
	        return (
	            <form role='form' onSubmit={handleSubmit}>

					<button type='button' className='btn btn-default'
						onClick={this.props.modoLista}>Voltar</button>

					<div className='box-body'>
						<div className='row'>
							<div className='col-sm-12'>
								<Field name='nome_cliente' component={LabelAndInput} readOnly={readOnly}
									label='Cliente' cols='12 6' placeholder='Informe o Cliente' />
								</div>
							<div className='col-sm-12'>
								<Field name='nome_unidade' component={LabelAndInput} readOnly={readOnly}
									label='Unidade' cols='12 6' placeholder='Informe a Unidade' />
							</div>
						</div>
						<Field name='competencia_inicio' type="month" component={LabelAndInput} readOnly={readOnly}
							label='Competência Inicial' cols='12 4 3' placeholder='Informe a Competência Inicial' />

						<Field name='competencia_fim' type="month" component={LabelAndInput} readOnly={readOnly}
							label='Competência Final' cols='12 4 3' placeholder='Informe a Competência Final' />

						<div className='row'>
							<div className='col-sm-12'>
								<Field name='competencia_aniversario' type="month" component={LabelAndInput} readOnly={readOnly}
									label='Competência do Aniversário' cols='12 4 3' placeholder='Informe a Competência do Aniversário' />
							</div>
						</div>
					</div>

					<ListaDemandaItens />

	            </form>
	        );
		} else {
			return (
	            <form role='form' onSubmit={handleSubmit}>
	                <div className='box-body'>
						<div className='row'>
							<div className='col-sm-12'>
								<Field name='id_cliente' component={Select} readOnly={readOnly}
									onChange={(e) => {
										this.props.getListaUnidade(e.target.value);
									}}
									value={this.props.clienteSelecionado}
									options={listaCliente} readOnly={readOnly}
									label='Cliente' cols='12 6' placeholder='Selecione o Cliente' />
							</div>
							<div className='col-sm-12'>
								<Field name='id_unidade' component={Select} readOnly={readOnly}
									onChange={this.props.changeValueUnidade}
									value={this.props.unidadeSelecionado}
									options={listaUnidade}
									label='Unidade' cols='12 6' placeholder='Selecione a Unidade' />
							</div>
						</div>
						<Field name='competencia_inicio' type="month" component={LabelAndInput} readOnly={readOnly}
							label='Competência Inicial' cols='12 4 3' placeholder='Informe a Competência Inicial' />

						<Field name='competencia_fim' type="month" component={LabelAndInput} readOnly={readOnly}
							label='Competência Final' cols='12 4 3' placeholder='Informe a Competência Final' />

						<div className='row'>
							<div className='col-sm-12'>
								<Field name='competencia_aniversario' type="month" component={LabelAndInput} readOnly={readOnly}
									label='Competência do Aniversário' cols='12 4 3' placeholder='Informe a Competência do Aniversário' />
							</div>
						</div>

					</div>
	                <div className='box-footer'>
	                    <button type='submit' className={`btn btn-${this.props.submitClass}`}>
	                        {this.props.submitLabel}
	                    </button>
	                    <button type='button' className='btn btn-default'
	                        onClick={this.props.modoLista}>Cancelar</button>
	                </div>
	            </form>
	        );
		}
    }
}

DemandaForm = reduxForm({form: 'demandaForm', destroyOnUnmount: false})(DemandaForm);
const mapStateToProps = state => ({
	listaDemandaItemTipo: state.demanda.listaDemandaItemTipo,
	listaCliente: state.demanda.listaCliente,
	listaUnidade: state.demanda.listaUnidade,
    clienteSelecionado: state.demanda.clienteSelecionado
});
const mapDispatchToProps = dispatch => bindActionCreators({ modoLista, getListaUnidade }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DemandaForm);
