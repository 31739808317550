import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormatarValor from '../common/form/formatarValor';
import LabelAndInput from '../common/form/labelAndInput';
import { getDataAtual, getDataFormatada, getData } from '../utils/dataUtils';
import { modoAlteracao, modoExclusao, modoFaturaItemAlteracao, alterarFaturaItem, removerFaturaItem, modoFaturaItemInclusao } from './faturaActions';
import ReactToPdf from 'react-to-pdf';
import Grid from '../common/layout/grid'
import imagemLogo from '../assets/images/vetor_logo.jpg';
import Chart from 'react-google-charts';
import { LineChart, PieChart, BarChart } from 'react-chartkick'
import 'chart.js'
var childrens = null;

class FaturaPdf extends Component {

	componentDidMount() {
		let contentWrapper = document.getElementById('id-content-wrapper');
		contentWrapper.classList.remove('content-wrapper');
	}

	componentWillUnmount() {
		let contentWrapper = document.getElementById('id-content-wrapper');
		contentWrapper.classList.add('content-wrapper');
	}

	render() {
		return (this.montarPdf());
	}

	montarPdf() {
		const ref = React.createRef();

		const container = {
			marginLeft: 210
		};

		const principal = {
			width: 793,
			height: 1122,
			paddingLeft: 40,
			paddingRight: 40,
			paddingTop: 25
		};

		const campoApresentacao = {
			paddingTop: 0,
			paddingBottom: 0,
			marginTop: 0,
			marginBottom: 0
		}

		const borda = {
			borderStyle: 'solid',
			borderTopWidth: 1,
			borderBottomWidth: 0,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderColor: '#000'
		};

		const bordaCampoApresentacao = {
			borderStyle: 'solid',
			borderTopWidth: 0,
			borderBottomWidth: 0,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderColor: '#000',
			paddingTop: 0,
			paddingBottom: 0,
			marginTop: 0,
			marginBottom: 0
		};

		const campoApresentacaoLabel = {
			marginBottom: 0,
			marginTop: 0,
			paddingTop: 0,
			paddingBottom: 0,
		}

		const bordaSeparacaoGrafico = {
			borderStyle: 'solid',
			borderTopWidth: 0,
			borderBottomWidth: 0,
			borderLeftWidth: 0,
			borderRightWidth: 1,
			borderColor: '#000'
		};

		const bordaFinal = {
			borderStyle: 'solid',
			borderTopWidth: 1,
			borderBottomWidth: 1,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderColor: '#000'
		};

        return (
			<div style={container}>
				<ReactToPdf targetRef={ref} filename={`${this.props.nome_cliente} - ${this.props.mes_faturado}.pdf`} options={{ orientation: 'portrait' }}>
					{({toPdf}) => (
						<div>
							<button type='button' className='btn btn-default'
								onClick={() => {

									let contentWrapper = document.getElementById('id-content-wrapper');
									contentWrapper.classList.add('content-wrapper');

									if (this.props.telaAnterior == 'alteracao') {
										this.props.modoAlteracao({
											...this.props,
											data_inicio_consumo: getDataFormatada(this.props.data_inicio_consumo, 'DD/MM/YYYY'),
											data_fim_consumo: getDataFormatada(this.props.data_fim_consumo, 'DD/MM/YYYY'),
											data_vencimento: this.props.data_vencimento ? getDataFormatada(this.props.data_vencimento, 'DD/MM/YYYY') : ''
										});
									} else {
										this.props.modoFaturaItemInclusao({
											id_fatura: this.props.id,
											id_unidade: this.props.id_unidade,
											id_cliente: this.props.id_cliente,
											nome_unidade: this.props.nome_unidade,
											nome_cliente: this.props.nome_cliente,
											competencia: this.props.competencia,
											data_inicio_consumo: moment(new Date(this.props.data_inicio_consumo)).format('DD/MM/YYYY'),
											data_fim_consumo: moment(new Date(this.props.data_fim_consumo)).format('DD/MM/YYYY'),
											data_vencimento: this.props.data_vencimento ? moment(new Date(this.props.data_vencimento)).format('DD/MM/YYYY') : '',
											concessionaria: this.props.concessionaria,
											resumo: this.props.resumo
										});
									}
								}}>Voltar</button>
							<button type='button' className='btn btn-primary' onClick={toPdf}>
								<i className='fa fa-download'> Baixar</i>
							</button>
						</div>
					)}
				</ReactToPdf>
				<div style={principal} ref={ref}>

					<div className='row' style={borda}>
						<Grid cols='5'>
							<img src={imagemLogo} style={{ height: 105.88, width: 300 }} />
						</Grid>
						<Grid cols='7'>
							<div className='row' style={{ textAlign: 'center', marginTop: 10 }}>
								<label>Vetor</label> CNPJ 25.155.189/0001-12
							</div>
							<div className='row' style={{ textAlign: 'center' }}>
								Linha 2 Oeste, RS 522, Km 02 - Dis. Industrial - Barreiro
							</div>
							<div className='row' style={{ textAlign: 'center' }}>
								Ijuí - RS CEP 98700-000
							</div>
							<div className='row' style={{ textAlign: 'center' }}>
								Fone (55) 3308-1881 <label>vetoreletrotecnica@gmail.com</label>
							</div>
						</Grid>
					</div>
					<div className='row' style={borda}>
						<Grid cols='12'>
							<div className='row' style={{ textAlign: 'center' }}>
								<label>Planilha de Gestão da Energia Elétrica</label>
							</div>
						</Grid>
					</div>
					<div className='row' style={borda}>
						<Grid cols='8' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Nome / Razão Social: </label> {this.props.nome_cliente}
						</Grid>
						<Grid cols='4' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>UC: </label> {this.props.uc}
						</Grid>
					</div>
					<div className='row' style={bordaCampoApresentacao}>
						<Grid cols='8' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Endereço: </label> {this.props.endereco}
						</Grid>
						<Grid cols='4' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Consumo: </label> {this.props.consumo}
						</Grid>
					</div>
					<div className='row' style={bordaCampoApresentacao}>
						<Grid cols='8' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Cidade: </label> {this.props.cidade}
						</Grid>
						<Grid cols='4' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>CEP: </label> {this.props.cep}
						</Grid>
					</div>
					<div className='row' style={bordaCampoApresentacao}>
						<Grid cols='8' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Concessionária: </label> {this.props.concessionaria}
						</Grid>
						<Grid cols='4' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Mês faturado: </label> {this.props.mes_faturado}
						</Grid>
					</div>
					<div className='row' style={bordaCampoApresentacao}>
						<Grid cols='8' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Classificação: </label> {this.props.classificacao}
						</Grid>
						<Grid cols='4' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Vencimento: </label> {this.props.vencimento}
						</Grid>
					</div>
					<div className='row' style={bordaCampoApresentacao}>
						<Grid cols='12' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Demanda Contratada: </label> {this.props.demanda_contratada}
						</Grid>
					</div>
					<div className='row' style={bordaCampoApresentacao}>
						<Grid cols='8' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Apelido da UC: </label> {this.props.apelido}
						</Grid>
						<Grid cols='4' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Valor da fatura: </label> {this.props.valor_fatura}
						</Grid>
					</div>
					<div className='row' style={borda}>
						<Grid cols='6'>
							<div className='row' style={{ ...bordaSeparacaoGrafico, textAlign: 'center' }}>
								<label>Gráfico de Faturamento</label>
								<PieChart
									height="250px"
									prefix='R$'
									decimal=','
									thousands='.'
									legend={true}
									points={true}
									data={this.props.grafico_faturamento}
								/>
							</div>
						</Grid>
						<Grid cols='6'>
							<div className='row' style={{ textAlign: 'center' }}>
								<label>Consumo de Energia em KW/h</label>
								<BarChart
									height="250px"
									prefix=''
									decimal=','
									thousands='.'
									legend={true}
									dataset={{ borderWidth: 0 }}
									colors={[ 'red', 'blue' ]}
									data={this.props.grafico_consumo_energia}
								/>
							</div>
						</Grid>
					</div>
					<div className='row' style={borda}>
						<Grid cols='12'>
							<div className='row' style={{ textAlign: 'center' }}>
								<label>Histórico de Faturamento</label>
								<LineChart
									height="160px"
									prefix=''
									decimal=','
									thousands='.'
									legend={true}
									points={true}
									data={this.props.grafico_historico_faturamento}
								/>
							</div>
						</Grid>
					</div>
					<div className='row' style={borda}>
						<Grid cols='12'>
							<div className='row' style={{ textAlign: 'center' }}>
								<label>Geração de Energia KW</label>
								<LineChart
									height="160px"
									prefix=''
									decimal=','
									thousands='.'
									legend={true}
									points={true}
									data={this.props.grafico_historico_geracao_energia}
								/>
							</div>
						</Grid>
					</div>
					<div className='row' style={bordaFinal}>
						<Grid cols='12' style={{ height: 130 }}>
							<div className='row' style={{ textAlign: 'center' }}>
								<label>Nota Técnica</label>
							</div>
							<div className='row'>
								<Grid cols='12'>
									{this.props.resumo}
								</Grid>
							</div>
						</Grid>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => {
	return ({
		id_fatura: state.fatura.id,
		id_unidade: state.fatura.id_unidade,
		id_cliente: state.fatura.id_cliente,
		nome_unidade: state.fatura.nome_unidade,
		competencia: state.fatura.competencia,
		data_inicio_consumo: state.fatura.data_inicio_consumo,
		data_fim_consumo: state.fatura.data_fim_consumo,
		data_vencimento: state.fatura.data_vencimento,
		id: state.fatura.id,
		nome_cliente: state.fatura.nome_cliente,
		uc: state.fatura.uc,
		endereco: state.fatura.endereco,
		consumo: state.fatura.consumo,
		cidade: state.fatura.cidade,
		cep: state.fatura.cep,
		cpf_cnpj: state.fatura.cpf_cnpj,
		mes_faturado: state.fatura.mes_faturado,
		concessionaria: state.fatura.concessionaria,
		vencimento: state.fatura.vencimento,
		classificacao: state.fatura.classificacao,
		demanda_contratada: parseFloat(state.fatura.demanda_contratada).toFixed(2).toString().replace('.', ','),
		valor_fatura: `R$ ${parseFloat(state.fatura.valor_fatura).toFixed(2).toString().replace('.', ',')}`,
		apelido: state.fatura.apelido,
		grafico_faturamento: state.fatura.listaFaturaAgrupador.map(item => [item.descricao + ` (R$ ${parseFloat(item.valor).toFixed(2).toString().replace('.', ',')})`, item.valor]),
		grafico_consumo_energia: state.fatura.listarGraficoConsumo,
		grafico_historico_faturamento: state.fatura.listarGraficoHistoricoFaturamento,
		grafico_historico_geracao_energia: state.fatura.listaGraficoHistoricoGeracaoEnergia,
		resumo: state.fatura.resumo,
		telaAnterior: state.fatura.telaAnterior
	});
}
const mapDispatchToProps = dispatch => bindActionCreators({ modoAlteracao, modoExclusao, modoFaturaItemAlteracao, alterarFaturaItem, removerFaturaItem, modoFaturaItemInclusao}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FaturaPdf);
