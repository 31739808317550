import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import { modoLista, getListaUnidade, initForm } from './unidadeCargaActions';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';

class UnidadeCargaForm extends Component {

    render() {
        const { handleSubmit, readOnly, listaCliente, listaUnidade } = this.props;
		
		return (
			<form role='form' onSubmit={handleSubmit}>
				<div className='box-body'>
					<div className='row'>
						<Field name='id_cliente' component={Select} readOnly={readOnly}
							onChange={(e) => {
								this.props.getListaUnidade(e.target.value);
							}}
							value={this.props.clienteSelecionado}
							options={listaCliente} readOnly={readOnly}
							label='Cliente' cols='12 12' placeholder='Selecione o Cliente' />
						
						<Field name='id_unidade' component={Select} readOnly={readOnly}
							onChange={this.props.changeValueUnidade}
							value={this.props.unidadeSelecionado}
							options={listaUnidade}
							label='Unidade' cols='12 12' placeholder='Selecione a Unidade' />
					</div>
					<div className='row'>
						<Field
							name='nome'
							component={LabelAndInput}
							label='Nome'
							placeholder='Informe o nome'
							cols='12 12 12'
							readOnly={readOnly} />

						<LabelAndInputNumber
							readOnly={readOnly}
							label='Carga (CV) apenas para cálculo'
							cols='12 4'
							placeholder='Informe a carga'
							decimalScale={2}
							fixedDecimalScale={2}
							onChange={data => {
								this.props.initForm({
									...this.props.formularioValues,
									carga: parseInt(parseFloat((data.target.value || '0').replace('.', ',')) *  0.73551)
								});
							}} />

						<Field
							name='carga'
							component={LabelAndInputNumber}
							readOnly={readOnly}
							label='Carga (kW)'
							cols='12 4'
							placeholder='Informe a carga'
							decimalScale={0}
							fixedDecimalScale={0} />
							
						<Field
							name='observacao'
							component={LabelAndInput}
							label='Observação'
							placeholder='Informe a observação'
							cols='12 12 12'
							readOnly={readOnly} />
					</div>


				</div>
				<div className='box-footer'>
					<button type='submit' className={`btn btn-${this.props.submitClass}`}>
						{this.props.submitLabel}
					</button>
					<button type='button' className='btn btn-default'
						onClick={this.props.modoLista}>Cancelar</button>
				</div>
			</form>
		);
		
    }
}

UnidadeCargaForm = reduxForm({form: 'unidadeCargaForm', destroyOnUnmount: false})(UnidadeCargaForm);
const mapStateToProps = state => ({
	formularioValues: getFormValues('unidadeCargaForm')(state),
	listaCliente: state.unidadeCarga.listaCliente,
	listaUnidade: state.unidadeCarga.listaUnidade,
	clienteSelecionado: state.unidadeCarga.clienteSelecionado
});
const mapDispatchToProps = dispatch => bindActionCreators({ modoLista, getListaUnidade, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UnidadeCargaForm);
