import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import Tabs from '../common/tab/tabs';
import TabsHeader from '../common/tab/tabsHeader';
import TabsContent from '../common/tab/tabsContent';
import TabHeader from '../common/tab/tabHeader';
import TabContent from '../common/tab/tabContent';
import { incluir, alterar, excluir, modoLista, modoInclusao } from './configuracaoActions';

import Lista from './configuracaoLista';
import Form from './configuracaoForm';

class Configuracao extends Component {

    componentWillMount() {
        this.props.modoLista();
    }

    render() {
        return (
            <div>
                <ContentHeader title='Configuração' small='Cadastro' />
                <Content>
                    <Tabs>
                        <TabsContent>
                            <TabContent id='modoLista'>
                                <Lista />
                            </TabContent>
                            <TabContent id='modoInclusao'>
                                <Form onSubmit={this.props.incluir}
                                    submitLabel='Incluir' submitClass='primary' />
                            </TabContent>
                            <TabContent id='modoAlteracao'>
                                <Form onSubmit={this.props.alterar}
                                    submitLabel='Alterar' submitClass='info' />
                            </TabContent>
                            <TabContent id='modoExclusao'>
                                <Form onSubmit={this.props.excluir}
                                    submitLabel='Excluir' submitClass='danger' readOnly='readOnly' />
                            </TabContent>
                        </TabsContent>
                    </Tabs>
                </Content>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ incluir, alterar, excluir, modoLista, modoInclusao }, dispatch);
export default connect(null, mapDispatchToProps)(Configuracao);
