import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { reset as resetForm, initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';

const INITIAL_VALUES = { nome: '' };

export function getLista(id_unidade) {
    const request = axios.get(`${consts.API_URL}/fatura?id_unidade=${id_unidade}`);
    return {
        type: 'FATURA_LISTADA',
        payload: request
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarselect`);
    return {
        type: 'FATURA_CLIENTE_LISTADO',
        payload: request
    };
}

export function getListaUnidade(id_cliente) {
    const request = axios.get(`${consts.API_URL}/unidade/listarselect?id_cliente=${id_cliente}`);
    return {
        type: 'FATURA_UNIDADE_LISTADA',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarselect`);
    return {
        type: 'FATURA_PRODUTO_LISTADO',
        payload: request
    };
}

export function getListaFaturaItem(faturaItem) {
    const request = axios.get(`${consts.API_URL}/faturaitem?id_fatura=${faturaItem.id_fatura}`);
    return {
        type: 'FATURA_ITEM_LISTADA',
        payload: request
    };
}

export function getFatura(fatura) {
    const request = axios.get(`${consts.API_URL}/fatura?id=${fatura.id_fatura}`);
    return {
        type: 'FATURA_CARREGADA',
        payload: request
    };
}

export function setTelaAnterior(telaAnterior) {
    return {
        type: 'FATURA_IMPRESSAO_TELA_ANTERIOR',
        payload: telaAnterior
    };
}

export function listarGraficoConsumo(fatura) {
	const request = axios.get(`${consts.API_URL}/fatura/listarGraficoConsumo?id_unidade=${fatura.id_unidade}`);
    return {
        type: 'FATURA_GRAFICO_CONSUMO_LISTADO',
        payload: request
    };
}

export function listarGraficoHistoricoFaturamento(fatura) {
	const request = axios.get(`${consts.API_URL}/fatura/listarGraficoHistoricoFaturamento?id_unidade=${fatura.id_unidade}`);
    return {
        type: 'FATURA_GRAFICO_HISTORICO_FATURAMENTO_LISTADO',
        payload: request
    };
}

export function listarGraficoHistoricoGeracaoEnergia(fatura) {
	const request = axios.get(`${consts.API_URL}/fatura/listarGraficoHistoricoGeracaoEnergia?id_unidade=${fatura.id_unidade}`);
    return {
        type: 'FATURA_GRAFICO_GERACAO_ENERGIA_LISTADO',
        payload: request
    };
}

export function getListaFaturaAgrupador(faturaItem) {
    const request = axios.get(`${consts.API_URL}/faturaitem/agrupador?id_fatura=${faturaItem.id_fatura}`);
    return {
        type: 'FATURA_AGRUPADOR_LISTADO',
		payload: request
	};
}

export function incluir(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/fatura`, registro)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
				axios.get(`${consts.API_URL}/fatura?id=${resp.data}`, registro)
		            .then(resp => {
		                if (resp.data) {
			                dispatch(modoFaturaItemInclusao({
								id_fatura: resp.data.id,
								id_unidade: resp.data.id_unidade,
								id_cliente: resp.data.id_cliente,
								nome_unidade: resp.data.nome_unidade,
								nome_cliente: resp.data.nome_cliente,
								competencia: resp.data.competencia,
								data_vencimento: moment(new Date(resp.data.data_vencimento)).format('DD/MM/YYYY'),
								data_inicio_consumo: moment(new Date(resp.data.data_inicio_consumo)).format('DD/MM/YYYY'),
								data_fim_consumo: moment(new Date(resp.data.data_fim_consumo)).format('DD/MM/YYYY'),
								concessionaria: resp.data.concessionaria,
								resumo: resp.data.resumo
							}));
						} else {
							dispatch(modoLista());
						}
		            })
		            .catch(e => {
		                if (e.response.status == 400) {
		                    e.response.data.forEach(retorno => {
		                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
		                    });
		                } else {
		                    toastr.error('Erro', 'Erro ao realizar Operação!!');
		                }
		            });
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function alterar(registro) {

	let data_inicio_consumo = registro.data_inicio_consumo.split('/')[2] + '/' + registro.data_inicio_consumo.split('/')[1] + '/' + registro.data_inicio_consumo.split('/')[0];
	let data_fim_consumo = registro.data_fim_consumo.split('/')[2] + '/' + registro.data_fim_consumo.split('/')[1] + '/' + registro.data_fim_consumo.split('/')[0];
	let data_vencimento = registro.data_vencimento.split('/')[2] + '/' + registro.data_vencimento.split('/')[1] + '/' + registro.data_vencimento.split('/')[0];

    return dispatch => {
        axios.post(`${consts.API_URL}/fatura`, { ...registro, data_inicio_consumo: data_inicio_consumo, data_fim_consumo: data_fim_consumo, data_vencimento: data_vencimento })
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/fatura?id=${registro.id}`)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function copiarMesAnterior(fatura) {

	return dispatch => {
        axios.post(`${consts.API_URL}/faturaitem/copiarmesanterior`, { id_fatura: fatura.id })
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
				dispatch(getListaFaturaItem({ id_fatura: fatura.id }));
				dispatch(getListaFaturaAgrupador({ id_fatura: fatura.id }));
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function adicionarFaturaItem(registro) {

	return dispatch => {
        axios.post(`${consts.API_URL}/faturaitem`, { id_fatura: registro.id_fatura, id_produto: registro.id_produto, valor_unitario : 0, quantidade: 0, valor_total: 0, impostos: 0 })
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoFaturaItemInclusao({ ...registro, id_produto: null }));
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function alterarFaturaItem(registro) {

	return dispatch => {
        axios.post(`${consts.API_URL}/faturaitem`, registro)
            .then(resp => {
                // toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                // dispatch(modoFaturaItemInclusao({ id_fatura: registro.id_fatura, nome_fatura: registro.nome_fatura }));
				// dispatch(getListaFaturaItem(registro));
				dispatch(getListaFaturaAgrupador(registro));
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        // toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function removerFaturaItem(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/faturaitem?id=${registro.id}`)
            .then(resp => {
				dispatch(getListaFaturaItem(registro));
				dispatch(getListaFaturaAgrupador(registro));
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function modoLista() {
    return [
		getListaFaturaItem({ id_fatura: 0 }),
		getListaFaturaAgrupador({ id_fatura: 0 }),
        showTabs('modoLista'),
        selectTab('modoLista'),
        initialize('faturaForm', INITIAL_VALUES)
    ];
}

export function modoInclusao(fatura) {
    return [
        showTabs('modoInclusao'),
        selectTab('modoInclusao'),
		getListaCliente(),
		getListaUnidade(0),
        initialize('faturaForm', fatura)
    ];
}

export function modoAlteracao(fatura) {
    return [
        showTabs('modoAlteracao'),
        selectTab('modoAlteracao'),
		getListaCliente(),
		getListaProduto(),
		getListaUnidade(fatura.id_cliente),
		getListaFaturaItem({ id_fatura: fatura.id }),
		getListaFaturaAgrupador({ id_fatura: fatura.id }),
        initialize('faturaForm', fatura)
    ];
}

export function modoExclusao(fatura) {
    return [
        showTabs('modoExclusao'),
        selectTab('modoExclusao'),
		getListaCliente(),
		getListaProduto(),
		getListaUnidade(fatura.id_cliente),
		getListaFaturaItem({ id_fatura: fatura.id }),
		getListaFaturaAgrupador({ id_fatura: fatura.id }),
        initialize('faturaForm', fatura)
    ];
}

export function modoFaturaItemInclusao(fatura) {
    return [
        showTabs('modoFaturaItemInclusao'),
        selectTab('modoFaturaItemInclusao'),
		getListaProduto(),
		getFatura(fatura),
		getListaFaturaItem(fatura),
		getListaFaturaAgrupador(fatura),
        initialize('faturaForm', fatura)
    ];
}

export function modoFaturaItemAlteracao(faturaItem) {
    return [
        showTabs('modoFaturaItemAlteracao'),
        selectTab('modoFaturaItemAlteracao'),
		getFatura({ id_fatura: faturaItem.id_fatura }),
		getListaFaturaItem({ id_fatura: faturaItem.id_fatura }),
		getListaFaturaAgrupador({ id_fatura: faturaItem.id_fatura }),
        initialize('faturaForm', faturaItem)
    ];
}

export function modoImpressao(fatura) {
    return [
        showTabs('modoImpressao'),
        selectTab('modoImpressao'),
		getFatura({ id_fatura: fatura.id, telaAnterior: fatura.telaAnterior }),
		setTelaAnterior(fatura.telaAnterior),
		getListaFaturaItem({ id_fatura: fatura.id }),
		getListaFaturaAgrupador({ id_fatura: fatura.id }),
		listarGraficoConsumo({ id_unidade: fatura.id_unidade }),
		listarGraficoHistoricoFaturamento({ id_unidade: fatura.id_unidade }),
		listarGraficoHistoricoGeracaoEnergia({ id_unidade: fatura.id_unidade }),
        initialize('faturaForm', fatura)
    ];
}
