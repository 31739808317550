import React from 'react'
import Navbar from './navbar'
import imagemLogo from '../../assets/images/vetor_logomarca.jpg';

export default props => (
    <header className='main-header'>
        <a href='/#/' className='logo'>
            <span className='logo-mini'>
                <img src={imagemLogo} style={{ height: 28, marginRight: 0, marginBottom: 3 }} />
            </span>
            <span className='logo-lg'>
				<img src={imagemLogo} style={{ height: 28, marginRight: 10, marginBottom: 3 }} />
                <b> Vetor</b>
            </span>
        </a>
        <nav className='navbar navbar-static-top'>
            <a href className='sidebar-toggle' data-toggle='offcanvas'></a>
			<Navbar />
        </nav>
    </header>
)
