import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';

const INITIAL_VALUES = { descricao: '', id_tipo_produto: null, compoe_grafico_consumo: false, compoe_demanda_lida: false, compoe_geracao_energia: false };

export function getLista() {
    const request = axios.get(`${consts.API_URL}/produto`);
    return {
        type: 'PRODUTO_LISTADO',
        payload: request
    };
}

export function getListaTipoProduto() {
    const request = axios.get(`${consts.API_URL}/tipoproduto/listarselect`);
    return {
        type: 'TIPO_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function changeValueTipoProduto(e) {
    return {
        type: 'TIPO_PRODUTO_VALUE_CHANGED',
        payload: e.target.value
    }
}

export function changeValueCompoeGraficoConsumo(e) {
    return {
        type: 'COMPOE_GRAFICO_CONSUMO_VALUE_CHANGED',
        payload: e.target.value
    }
}

export function changeValueCompoeDemandaLida(e) {
    return {
        type: 'COMPOE_DEMANDA_LIDA_VALUE_CHANGED',
        payload: e.target.value
    }
}

export function changeValueCompoeGeracaoEnergia(e) {
    return {
        type: 'COMPOE_GERACAO_ENERGIA_VALUE_CHANGED',
        payload: e.target.value
    }
}

export function incluir(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/produto`, {
			...registro,
			compoe_grafico_consumo: registro.compoe_grafico_consumo == 1 ? true : false,
			compoe_demanda_lida: registro.compoe_demanda_lida == 1 ? true : false,
			compoe_geracao_energia: registro.compoe_geracao_energia == 1 ? true : false
		})
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    if (e.response.data instanceof Array) {
                        e.response.data.forEach(retorno => {
                            toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                        });
                    } else {
                        toastr.error('Erro', e.response.data);
                    }
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function alterar(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/produto`, {
			...registro,
			compoe_grafico_consumo: registro.compoe_grafico_consumo == 1 ? true : false,
			compoe_demanda_lida: registro.compoe_demanda_lida == 1 ? true : false,
			compoe_geracao_energia: registro.compoe_geracao_energia == 1 ? true : false
		})
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    if (e.response.data instanceof Array) {
                        e.response.data.forEach(retorno => {
                            toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                        });
                    } else {
                        toastr.error('Erro', e.response.data);
                    }
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/produto?id=${registro.id}`)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function modoLista() {
    return [
        showTabs('modoLista'),
        selectTab('modoLista'),
        getLista(),
        initialize('produtoForm', INITIAL_VALUES)
    ];
}

export function modoInclusao(produto) {
    return [
        showTabs('modoInclusao'),
        selectTab('modoInclusao'),
        initialize('produtoForm', produto)
    ];
}

export function modoAlteracao(produto) {
    return [
        showTabs('modoAlteracao'),
        selectTab('modoAlteracao'),
        initialize('produtoForm', produto)
    ];
}

export function modoExclusao(produto) {
    return [
        showTabs('modoExclusao'),
        selectTab('modoExclusao'),
        initialize('produtoForm', produto)
    ];
}
