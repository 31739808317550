import React from 'react'
import NumberFormat from 'react-number-format';

export default props => (
    <NumberFormat value={props.valor ? props.valor.toString().replace('.', ',') : 0}
		style={props.style}
		onChange={props.onChange}
		displayType={props.displayType ? props.displayType : 'text'}
		decimalSeparator={","}
		decimalScale={props.casas}
		fixedDecimalScale={props.casas}  />
)
