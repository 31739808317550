import './auth.css';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, signup } from './authActions';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import If from '../common/operator/if';
import Messages from '../common/msg/messages';
import Input from '../common/form/inputAuth';
import InputPassword from '../common/form/inputPassword';

class Auth extends Component {
	constructor(props) {
		super(props);
		this.state = {loginMode: true};
	}

	changeMode() {
		this.setState({ loginMode: !this.state.loginMode });
	}

	onSubmit(values) {
		const { login, signup } = this.props;
		this.state.loginMode ? login(values) : signup(values);
	}

	render() {
		const { loginMode } = this.state;
		const { handleSubmit } = this.props;

		return (
			<div className="login-box">
				<div className="login-logo"><b>Vetor</b></div>
				<div className="login-box-body">
					<p className="login-box-msg">Bem vindo!</p>
					<form onSubmit={handleSubmit(v => this.onSubmit(v))}>
						<Field component={Input} type="input" name="nome"
							placeholder="Nome" icon='user' hide={loginMode} />
						<Field component={Input} type="email" name="usuario"
							placeholder="E-mail" icon='envelope'/>
						<Field component={InputPassword} type={this.state.senhaVisivel ? 'input' : 'password'} name="senha" 
							placeholder="Senha" icon={this.state.senhaVisivel ? 'eye-slash' : 'eye'}
							onClickIcon={() => {
								this.setState({ ...this.state, senhaVisivel: !this.state.senhaVisivel })
							}} />
						<Field component={Input} type="password" name="confirm_password"
							placeholder="Confirmar Senha" icon='lock' hide={loginMode} />
						<Row>
							<Grid cols="4">
								<button type="submit"
									className="btn btn-primary btn-block btn-flat">
									{loginMode ? 'Entrar' : 'Registrar'}
								</button>
							</Grid>
						</Row>
					</form>
					<br />

				</div>
				<Messages />
			</div>
		);
	}
}
/*
VAI ABAIXO DO BR DEPOIS DO BOTÃO ENTRAR
<a onClick={() => this.changeMode()}>
 {loginMode ? 'Novo usuário? Registrar aqui!' :
	'Já é cadastrado? Entrar aqui!'}
</a>
*/

Auth = reduxForm({form: 'authForm'})(Auth);
const mapDispatchToProps = dispatch => bindActionCreators({ login, signup }, dispatch);
export default connect(null, mapDispatchToProps)(Auth);
