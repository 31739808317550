import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormatarValor from '../common/form/formatarValor';
import LabelAndInput from '../common/form/labelAndInput';
import { getDataAtual, getDataFormatada, getData } from '../utils/dataUtils';
import { modoLista, modoAlteracao, modoExclusao } from './ordemServicoActions';
import ReactToPdf from 'react-to-pdf';
import Grid from '../common/layout/grid'
import imagemLogo from '../assets/images/vetor_logo.jpg';
import Chart from 'react-google-charts';
import { LineChart, PieChart, BarChart } from 'react-chartkick'
import 'chart.js'
var childrens = null;

class OrdemServicoPdf extends Component {

	componentDidMount() {
		let contentWrapper = document.getElementById('id-content-wrapper');
		contentWrapper.classList.remove('content-wrapper');
	}

	render() {
		return (this.montarPdf());
	}

	montarPdf() {
		const ref = React.createRef();

		const container = {
			marginLeft: 210,
			overflowX: 'scroll'
		};

		const principal = {
			width: 793,
			height: 1122,
			paddingLeft: 40,
			paddingRight: 40,
			paddingTop: 25
		};

		const campoApresentacao = {
			paddingTop: 0,
			paddingBottom: 0,
			marginTop: 0,
			marginBottom: 0
		}

		const borda = {
			borderStyle: 'solid',
			borderTopWidth: 1,
			borderBottomWidth: 0,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderColor: '#000'
		};

		const bordaCampoApresentacao = {
			borderStyle: 'solid',
			borderTopWidth: 0,
			borderBottomWidth: 0,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderColor: '#000',
			paddingTop: 0,
			paddingBottom: 0,
			marginTop: 0,
			marginBottom: 0
		};

		const campoApresentacaoLabel = {
			marginBottom: 0,
			marginTop: 0,
			paddingTop: 0,
			paddingBottom: 0,
		}

		const bordaSeparacaoGrafico = {
			borderStyle: 'solid',
			borderTopWidth: 0,
			borderBottomWidth: 0,
			borderLeftWidth: 0,
			borderRightWidth: 1,
			borderColor: '#000'
		};

		const bordaSeparacao = {
			borderStyle: 'solid',
			borderTopWidth: 0,
			borderBottomWidth: 0,
			borderLeftWidth: 0,
			borderRightWidth: 1,
			borderColor: '#000'
		};

		const bordaFinal = {
			borderStyle: 'solid',
			borderTopWidth: 1,
			borderBottomWidth: 1,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderColor: '#000'
		};

		const checkboxTexto = {
			display: 'flex',
			alignItems: 'center'
		};

		const checkbox = {
			fontSize: 24,
			marginRight: 5
		};

		const colunaQuantidadeProdutos = {
			borderTop: '1px solid black',
			borderRight: '1px solid black',
			height: 25,
			textAlign: 'center'
		};

		const colunaProdutos = {
			borderTop: '1px solid black',
			height: 25,
			textAlign: 'center'
		};

		const tabelaProdutos = {
			width: 556
		};

		const blocoTipoServicos = {
			paddingRight: 0
		};

		const blocoProdutos = {
			paddingLeft: 0,
			paddingRight: 0
		};

		const blocoDescricaoAtividades = {
			paddingLeft: 0,
			paddingRight: 0
		};

		const colunaDescricaoAtividades = {
			borderTop: '1px solid black',
			height: 25,
			textAlign: 'center'
		};

		const tabelaDescricaoAtividades = {
			width: 742,
			marginLeft: -16
		};

		const colunaDadosComplementares = {
			borderTop: '1px solid black',
			borderRight: '1px solid black',
			height: 25,
			paddingLeft: 5
		};

		const colunaDadosComplementaresUltima = {
			borderTop: '1px solid black',
			height: 25,
			paddingLeft: 5
		};

		const tabelaDadosComplementares = {
			width: 743,
			marginLeft: -16
		};

		const colunaDespesas = {
			borderTop: '1px solid black',
			borderRight: '1px solid black',
			height: 25,
			paddingLeft: 5
		};

		const colunaDespesasUltima = {
			borderTop: '1px solid black',
			height: 25,
			paddingLeft: 5
		};

		const tabelaDespesas = {
			width: 371,
			marginLeft: -16
		};

		const checkboxDespesasTexto = {
			display: 'flex',
			alignItems: 'center'
		};

		const tabelaAssinatura = {
			width: '100%',
			marginTop: 30,
			marginBottom: 5
		};

		const colunaAssinatura = {
			textAlign: 'center',
			borderTop: '1px solid black'
		};

		let ucUnidade = '';
		let cpfCnpjUnidade = '';
		let nomeUnidade = '';
		let enderecoUnidade = '';
		let cidadeUnidade = '';
		let cepUnidade = '';

		if (this.props.ordemServicoImpressao && this.props.ordemServicoImpressao.unidades) {
			this.props.ordemServicoImpressao.unidades.forEach((unidade, i) => {
				ucUnidade += i == 0 ? unidade.uc_unidade : ', ' + unidade.uc_unidade;
				let cpfCnpj = unidade.cpf_cnpj_unidade.length == 11
					? unidade.cpf_cnpj_unidade.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, (regex, a1, a2, a3, a4) => a1 + '.' + a2 + '.' + a3 + '-' + a4)
					: unidade.cpf_cnpj_unidade.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, (regex, a1, a2, a3, a4, a5) => a1 + '.' + a2 + '.' + a3 + '/' + a4 + '-' + a5)
				cpfCnpjUnidade += i == 0 ? cpfCnpj : ', ' + cpfCnpj;
				nomeUnidade += i == 0 ? unidade.nome_unidade : ', ' + unidade.nome_unidade;
				enderecoUnidade += i == 0 ? unidade.endereco_unidade : ', ' + unidade.endereco_unidade;
				cidadeUnidade += i == 0 ? unidade.cidade_unidade : ', ' + unidade.cidade_unidade;
				let cep = unidade.cep_unidade.replace(/(\d{2})(\d{3})(\d{3})/, (regex, a1, a2, a3) => a1 + a2 + '-' + a3)
				cepUnidade += i == 0 ? cep : ', ' + cep;
			});

		}

        return (
			<div style={container}>
				<ReactToPdf targetRef={ref} filename={`OS - ${this.props.ordemServicoImpressao.numero}.pdf`} options={{ orientation: 'portrait' }}>
					{({toPdf}) => (
						<div>
							<button type='button' className='btn btn-default'
								onClick={() => {

									let contentWrapper = document.getElementById('id-content-wrapper');
									contentWrapper.classList.add('content-wrapper');

									if (this.props.telaAnterior == 'alteracao') {
										this.props.modoAlteracao({
											...this.props,
											data_inicio_consumo: getDataFormatada(this.props.data_inicio_consumo, 'DD/MM/YYYY'),
											data_fim_consumo: getDataFormatada(this.props.data_fim_consumo, 'DD/MM/YYYY'),
											data_vencimento: this.props.data_vencimento ? getDataFormatada(this.props.data_vencimento, 'DD/MM/YYYY') : ''
										});
									} else {
										this.props.modoLista({
											id_fatura: this.props.id,
											id_unidade: this.props.id_unidade,
											id_cliente: this.props.id_cliente,
											nome_unidade: this.props.nome_unidade,
											nome_cliente: this.props.nome_cliente,
											competencia: this.props.competencia,
											data_inicio_consumo: moment(new Date(this.props.data_inicio_consumo)).format('DD/MM/YYYY'),
											data_fim_consumo: moment(new Date(this.props.data_fim_consumo)).format('DD/MM/YYYY'),
											data_vencimento: this.props.data_vencimento ? moment(new Date(this.props.data_vencimento)).format('DD/MM/YYYY') : '',
											concessionaria: this.props.concessionaria,
											resumo: this.props.resumo
										});
									}
								}}>Voltar</button>
							<button type='button' className='btn btn-primary' onClick={toPdf}>
								<i className='fa fa-download'> Baixar</i>
							</button>
						</div>
					)}
				</ReactToPdf>
				<div style={principal} ref={ref}>

					<div className='row' style={borda}>
						<Grid cols='5'>
							<img src={imagemLogo} style={{ height: 105.88, width: 300 }} />
						</Grid>
						<Grid cols='7'>
							<div className='row' style={{ textAlign: 'center', marginTop: 10 }}>
								<label>Vetor</label> CNPJ 25.155.189/0001-12
							</div>
							<div className='row' style={{ textAlign: 'center' }}>
								Linha 2 Oeste, RS 522, Km 02 - Dis. Industrial - Barreiro
							</div>
							<div className='row' style={{ textAlign: 'center' }}>
								Ijuí - RS CEP 98700-000
							</div>
							<div className='row' style={{ textAlign: 'center' }}>
								Fone (55) 3308-1881 <label>vetoreletrotecnica@gmail.com</label>
							</div>
						</Grid>
					</div>
					<div className='row' style={borda}>
						<Grid cols='12'>
							<div className='row' style={{ textAlign: 'center' }}>
								<label>Formulário de Ordens de Serviço</label>
							</div>
						</Grid>
					</div>
					<div className='row' style={borda}>
						<Grid cols='8' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Nome / Razão Social: </label> {this.props.ordemServicoImpressao ? this.props.ordemServicoImpressao.nome_cliente : ''}
						</Grid>
						<Grid cols='4' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Nº OS: </label> {this.props.ordemServicoImpressao.numero}
						</Grid>
					</div>
					<div className='row' style={bordaCampoApresentacao}>
						<Grid cols='8' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>CNPJ/CPF: </label> {cpfCnpjUnidade}
						</Grid>
						<Grid cols='4' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>UC: </label> {ucUnidade}
						</Grid>
					</div>
					<div className='row' style={bordaCampoApresentacao}>
						<Grid cols='8' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Endereço: </label> {enderecoUnidade}
						</Grid>
					</div>
					<div className='row' style={bordaCampoApresentacao}>
						<Grid cols='8' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Cidade: </label> {cidadeUnidade}
						</Grid>
						<Grid cols='4' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>CEP: </label> {cepUnidade}
						</Grid>
					</div>
					<div className='row' style={bordaCampoApresentacao}>
						<Grid cols='8' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Solicitante: </label> {this.props.ordemServicoImpressao.solicitante}
						</Grid>
						<Grid cols='4' style={campoApresentacao}>
							<label style={campoApresentacaoLabel}>Contato: </label> {this.props.ordemServicoImpressao.contato}
						</Grid>
					</div>
					<div className='row' style={{ ...borda, height: 130}}>
						<Grid cols='12'>
							<label>Atividade Solicitada</label>
							{(this.props.ordemServicoImpressao.descricao || '').split('\n').map(item => {
								return (
									<div>{item}</div>
								);
							})}
						</Grid>
					</div>
					<div className='row' style={{ ...borda, height: 200}}>
						<Grid cols='3' style={{ ...blocoTipoServicos, height: '100%' }}>
							<div style={{ ...bordaSeparacao, height: '100%' }}>
								<label>Tipo de Serviço</label>
								<div style={checkboxTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Projeto</div>
								<div style={checkboxTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Execução de Serviços</div>
								<div style={checkboxTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Laudo Técnico</div>
								<div style={checkboxTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Orçamento</div>
								<div style={checkboxTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Inspeção Técnica</div>
								<div style={checkboxTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Assistência Técnica</div>
								<div style={checkboxTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Outros</div>
							</div>
						</Grid>
						<Grid cols='9' style={blocoProdutos}>
							<label style={{ marginLeft: 16 }}>Material Utilizado</label>
							<table style={tabelaProdutos}>
								<tHead>
									<tr>
										<th style={{ ...colunaQuantidadeProdutos, width: 50 }}>Qtd</th>
										<th style={colunaProdutos}>Descrição de Produtos</th>
									</tr>
								</tHead>
								<tBody>
									<tr>
										<td style={colunaQuantidadeProdutos}></td>
										<td style={colunaProdutos}></td>
									</tr>
									<tr>
										<td style={colunaQuantidadeProdutos}></td>
										<td style={colunaProdutos}></td>
									</tr>
									<tr>
										<td style={colunaQuantidadeProdutos}></td>
										<td style={colunaProdutos}></td>
									</tr>
									<tr>
										<td style={colunaQuantidadeProdutos}></td>
										<td style={colunaProdutos}></td>
									</tr>
									<tr>
										<td style={colunaQuantidadeProdutos}></td>
										<td style={colunaProdutos}></td>
									</tr>
									<tr>
										<td style={colunaQuantidadeProdutos}></td>
										<td style={colunaProdutos}></td>
									</tr>
								</tBody>
							</table>
						</Grid>
					</div>
					<div className='row' style={{ ...borda }}>
						<Grid cols='12' >
							<label>Descrição da Atividade</label>
							<table style={tabelaDescricaoAtividades}>
								<tBody>
									<tr><td style={colunaDescricaoAtividades}></td></tr>
									<tr><td style={colunaDescricaoAtividades}></td></tr>
									<tr><td style={colunaDescricaoAtividades}></td></tr>
									<tr><td style={colunaDescricaoAtividades}></td></tr>
									<tr><td style={colunaDescricaoAtividades}></td></tr>
								</tBody>
							</table>
						</Grid>
					</div>
					<div className='row' style={{ ...borda }}>
						<Grid cols='12' >
							<label>Dados Complementares</label>
							<table style={tabelaDadosComplementares}>
								<tBody>
									<tr>
										<th width={247} style={colunaDadosComplementares}>Km Saída:</th>
										<th width={247} style={colunaDadosComplementares}>Km Chegada:</th>
										<th width={248} colspan={2} style={colunaDadosComplementaresUltima}>Km Total:</th>
									</tr>
									<tr>
										<th width={247} style={colunaDadosComplementares}>Nome do Técnico:</th>
										<th width={247} style={colunaDadosComplementares}>Dias de Trabalho:</th>
										<th width={124} style={colunaDadosComplementares}>Hora Início:</th>
										<th width={124} style={colunaDadosComplementaresUltima}>Hora Término:</th>
									</tr>
									<tr>
										<td width={247} style={colunaDadosComplementares}></td>
										<td width={247} style={colunaDadosComplementares}></td>
										<td width={124} style={colunaDadosComplementares}></td>
										<td width={124} style={colunaDadosComplementaresUltima}></td>
									</tr>
									<tr>
										<td width={247} style={colunaDadosComplementares}></td>
										<td width={247} style={colunaDadosComplementares}></td>
										<td width={124} style={colunaDadosComplementares}></td>
										<td width={124} style={colunaDadosComplementaresUltima}></td>
									</tr>
									<tr>
										<td width={247} style={colunaDadosComplementares}></td>
										<td width={247} style={colunaDadosComplementares}></td>
										<td width={124} style={colunaDadosComplementares}></td>
										<td width={124} style={colunaDadosComplementaresUltima}></td>
									</tr>
								</tBody>
							</table>
						</Grid>
					</div>
					<div className='row' style={{ ...borda }}>
						<Grid cols='6' >
							<label>Despesas</label>
							<table style={{ ...tabelaDespesas, marginTop: -1 }}>
								<tBody>
									<tr>
										<td width={150} style={colunaDespesas}><div style={checkboxDespesasTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Encargos ADM</div></td>
										<td style={colunaDespesas}>R$</td>
									</tr>
									<tr>
										<td style={colunaDespesas}><div style={checkboxDespesasTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Refeição</div></td>
										<td style={colunaDespesas}>R$</td>
									</tr>
									<tr>
										<td style={colunaDespesas}><div style={checkboxDespesasTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Estadia</div></td>
										<td style={colunaDespesas}>R$</td>
									</tr>
									<tr>
										<td style={colunaDespesas}><div style={checkboxDespesasTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Pedágio</div></td>
										<td style={colunaDespesas}>R$</td>
									</tr>
								</tBody>
							</table>
						</Grid>
						<Grid cols='6' >
							<table style={{ ...tabelaDespesas, marginTop: -1, marginLeft: -17, width: 373 }}>
								<tBody>
									<tr>
										<td width={150} style={{ ...colunaDespesas, borderLeft: '1px solid black' }}><div style={checkboxDespesasTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Deslocamento</div></td>
										<td style={colunaDespesasUltima}>R$</td>
									</tr>
									<tr>
										<td style={colunaDespesas}><div style={checkboxDespesasTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Mão de Obra</div></td>
										<td style={colunaDespesasUltima}>R$</td>
									</tr>
									<tr>
										<td style={colunaDespesas}><div style={checkboxDespesasTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Materiais</div></td>
										<td style={colunaDespesasUltima}>R$</td>
									</tr>
									<tr>
										<td style={colunaDespesas}><div style={checkboxDespesasTexto}><i class='fa fa-square-o' aria-hidden='true' style={checkbox}></i>Outros</div></td>
										<td style={colunaDespesasUltima}>R$</td>
									</tr>
									<tr>
										<td style={{ ...colunaDespesas, fontWeight: 'bold', height: 25 }}>Total</td>
										<td style={colunaDespesasUltima}>R$</td>
									</tr>
								</tBody>
							</table>
						</Grid>
					</div>
					<div className='row' style={bordaFinal}>
						<Grid cols='12'>
								<label>Data:</label>
								<label style={{ paddingLeft: 40, paddingRight: 40 }}>/</label>
								<label style={{ paddingRight: 80 }}>/</label>
							<table style={tabelaAssinatura}>
								<tBody>
									<tr>
										<td width={200} style={colunaAssinatura}>Assinatura do Responsável Técnico</td>
										<td width={10}></td>
										<td width={200} style={colunaAssinatura}>Assinatura do Cliente</td>
									</tr>
								</tBody>
							</table>
						</Grid>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => {
	return ({
		ordemServicoImpressao: state.ordemServico.ordemServicoImpressao,
		id_fatura: state.fatura.id,
		id_unidade: state.fatura.id_unidade,
		id_cliente: state.fatura.id_cliente,
		nome_unidade: state.fatura.nome_unidade,
		competencia: state.fatura.competencia,
		data_inicio_consumo: state.fatura.data_inicio_consumo,
		data_fim_consumo: state.fatura.data_fim_consumo,
		data_vencimento: state.fatura.data_vencimento,
		id: state.fatura.id,
		nome_cliente: state.fatura.nome_cliente,
		uc: state.fatura.uc,
		endereco: state.fatura.endereco,
		consumo: state.fatura.consumo,
		cidade: state.fatura.cidade,
		cep: state.fatura.cep,
		cpf_cnpj: state.fatura.cpf_cnpj,
		mes_faturado: state.fatura.mes_faturado,
		concessionaria: state.fatura.concessionaria,
		vencimento: state.fatura.vencimento,
		classificacao: state.fatura.classificacao,
		demanda_contratada: parseFloat(state.fatura.demanda_contratada).toFixed(2).toString().replace('.', ','),
		valor_fatura: `R$ ${parseFloat(state.fatura.valor_fatura).toFixed(2).toString().replace('.', ',')}`,
		apelido: state.fatura.apelido,
		grafico_faturamento: state.fatura.listaFaturaAgrupador.map(item => [item.descricao + ` (R$ ${parseFloat(item.valor).toFixed(2).toString().replace('.', ',')})`, item.valor]),
		grafico_consumo_energia: state.fatura.listarGraficoConsumo,
		grafico_historico_faturamento: state.fatura.listarGraficoHistoricoFaturamento,
		grafico_historico_geracao_energia: state.fatura.listaGraficoHistoricoGeracaoEnergia,
		resumo: state.fatura.resumo,
		telaAnterior: state.fatura.telaAnterior
	});
}
const mapDispatchToProps = dispatch => bindActionCreators({ modoLista, modoAlteracao, modoExclusao }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrdemServicoPdf);
