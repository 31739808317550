import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import Tabs from '../common/tab/tabs';
import TabsHeader from '../common/tab/tabsHeader';
import TabsContent from '../common/tab/tabsContent';
import TabHeader from '../common/tab/tabHeader';
import TabContent from '../common/tab/tabContent';
import { modoLista } from './relatorioFaturaActions';

import Lista from './relatorioFaturaLista';

class RelatorioFatura extends Component {

    componentWillMount() {
        this.props.modoLista();
    }

    render() {
        return (
            <div>
                <ContentHeader title='Relatório de Faturas' small='Cadastro' />
                <Content>
                    <Tabs>
                        <TabsContent>
                            <TabContent id='modoLista'>
                                <Lista />
                            </TabContent>
                        </TabsContent>
                    </Tabs>
                </Content>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ modoLista }, dispatch);
export default connect(null, mapDispatchToProps)(RelatorioFatura);
