import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import LabelAndInput from '../common/form/labelAndInput';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { initForm, modoLista, getListaUnidade, modoImpressao } from './ordemServicoActions';

class OrdemServicoForm extends Component {

    render() {
        const { handleSubmit, readOnly, listaCliente, listaUnidade } = this.props;
        return (
            <form role='form' onSubmit={handleSubmit}>

                <div className='box-body'>
					<div className='row'>
						<Field name='id_cliente' component={Select} readOnly={readOnly}
							onChange={(e) => {
								this.props.getListaUnidade(e.target.value);
							}}
							value={this.props.clienteSelecionado}
							options={listaCliente} readOnly={readOnly}
							label='Cliente' cols='12 6 6' placeholder='Selecione o Cliente' />
					</div>
					<div className='row'>
						<Field name='id_unidade' component={Select} readOnly={readOnly}
							onChange={(data) => {
								let lista = this.props.formularioValues && this.props.formularioValues.unidades ? this.props.formularioValues.unidades : [];
								lista.push({
									id_unidade: data.target.value,
									nome_unidade: listaUnidade.filter(unidade => unidade.id == data.target.value)[0].valor
								});
								this.props.initForm({
									...this.props.formularioValues,
									unidades: lista
								});
							}}
							options={listaUnidade}
							label='Unidade' cols='12 6 6' placeholder='Selecione a Unidade' />
					</div>
					<div className='row'>
						<div class="col-xs-12 col-sm-6 col-md-6">
							<table className='table table-bordered table-striped dataTable'>
								<thead>
									<tr>
										<th>Unidades</th>
										<th width={60}>Ações</th>
									</tr>
								</thead>
								<tbody>
									{(this.props.formularioValues && this.props.formularioValues.unidades ? this.props.formularioValues.unidades : [])
										.filter(item => {
											return !item.id || item.ativo;
										}).map(item => (
										<tr key={item.id_unidade}>
											<td>{item.nome_unidade}</td>
											<td alignCenter>
												<button className='btn btn-danger' onClick={() => {
													let lista = [];
													this.props.formularioValues.unidades.forEach(unidade => {
														if (unidade.id_unidade == item.id_unidade) {
															if (unidade.id) {
																lista.push({
																	...unidade,
																	ativo: false
																});
															}
														} else {
															lista.push(unidade);
														}
													});
													this.props.initForm({
														...this.props.formularioValues,
														unidades: lista
													});
												}}>
													<i className='fa fa-trash-o'></i>
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
					<div className='row'>
						<Field
							name='data_agendamento'
							component={LabelAndInputMask}
							label='Data de Agendamento'
							placeholder='Informe a Data de Agendamento'
							mask={'99/99/9999'}
							cols='12 4 3'
							readOnly={readOnly} />
					</div>
					<div className='row'>
						<Field
							name='solicitante'
							component={LabelAndInput}
							label='Solicitante'
							placeholder='Informe o Solicitante'
							cols='12 4 4'
							readOnly={readOnly} />

						<Field
							name='contato'
							component={LabelAndInput}
							label='Contato'
							placeholder='Informe o Contato'
							cols='12 4 4'
							readOnly={readOnly} />
					</div>
					<div className='row'>
						<Field name='descricao' component={LabelAndTextarea} rows={5}
							label='Descrição' cols='12 12 8' placeholder='Informe a Descrição' />
					</div>
					{/*this.props.formularioValues && this.props.formularioValues.id ? (
						<div className='row'>
							<Field name='observacao' component={LabelAndTextarea} rows={5}
								label='Observação' cols='12 12 8' placeholder='Informe a Observação' />
						</div>
					) : null*/}
					{/*this.props.formularioValues && this.props.formularioValues.id ? (
						<div className='row'>
							<Field
								name='tempo'
								component={LabelAndInputMask}
								label='Tempo (HH:MM)'
								placeholder='00:00'
								mask={'99:99'}
								cols='12 6 3'
								readOnly={readOnly}
								onChange={(data) => {
									let tempo = data.target.value ? parseInt((parseInt(data.target.value.split(':')[0]) * 60) + parseInt(data.target.value.split(':')[1])) : 0;
									this.props.initForm({
										...this.props.formularioValues,
										tempo: data.target.value,
										valor_calculado: (tempo / 60) * parseFloat(String(this.props.formularioValues.valor_hora_tecnica || 0).replace(',', '.'))
									});
								}} />
							<Field
								name='valor_hora_tecnica'
								component={LabelAndInputNumber}
								readOnly={readOnly}
								label='Hora Técnica (R$)'
								cols='12 6 3'
								placeholder='Informe o Valor da Hora Técnica'
								casas={2}
								onChange={(data) => {
									let tempo = this.props.formularioValues.tempo ? parseInt((parseInt(this.props.formularioValues.tempo.split(':')[0]) * 60) + parseInt(this.props.formularioValues.tempo.split(':')[1])) : 0;
									this.props.initForm({
										...this.props.formularioValues,
										valor_hora_tecnica: data.target.value,
										valor_calculado: (tempo / 60) * parseFloat(String(data.target.value || 0).replace(',', '.'))
									});
								}} />
						</div>
					) : null*/}
					{/*this.props.formularioValues && this.props.formularioValues.id ? (
						<div className='row'>
							<Field
								name='valor_calculado'
								component={LabelAndInputNumber}
								readOnly={true}
								label='Valor Calculado (R$)'
								cols='12 6 3'
								placeholder='Informe o Valor Calculado'
								casas={2} />
							<Field
								name='valor_cobrado'
								component={LabelAndInputNumber}
								readOnly={readOnly}
								label='Valor Cobrado (R$)'
								cols='12 6 3'
								placeholder='Informe o Valor da Cobrado'
								casas={2} />
						</div>
					) : null*/}
                </div>
                <div className='box-footer'>
                    <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                        {this.props.submitLabel}
                    </button>
                    <button type='button' className='btn btn-default'
                        onClick={this.props.modoLista}>Cancelar
					</button>
					{this.props.formularioValues && this.props.formularioValues.id ? (
						<button type='button' className='btn btn-primary'
	                        onClick={() => this.props.modoImpressao({ id: this.props.formularioValues.id, id_cliente: this.props.formularioValues.id_cliente, telaAnterior: 'visualizacao' })}><i className='fa fa-file-pdf-o'> Gerar Resumo</i>
						</button>
					) : null}
                </div>
            </form>
        );
    }
}

OrdemServicoForm = reduxForm({form: 'ordemServicoForm', destroyOnUnmount: false})(OrdemServicoForm);
const mapStateToProps = state => ({
	listaCliente: state.ordemServico.listaCliente,
	listaUnidade: state.ordemServico.listaUnidade,
	formularioValues: getFormValues('ordemServicoForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ initForm, modoLista, getListaUnidade, modoImpressao }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrdemServicoForm);
