import moment from 'moment';

const INITIAL_STATE = {
	filtro: {
		dataInicial: `01/${moment(new Date()).format('MM/YYYY')}`,
		dataFinal: moment(new Date()).format('DD/MM/YYYY'),
		getDataBanco: (data) => `${data.split('/')[2]}-${data.split('/')[1]}-${data.split('/')[0]}`,
		id_cliente: 0,
		id_unidade: 0
	},
	listaCliente: [],
	listaUnidade: [],
	listaProduto: [],
	listaGraficoConsumoEnergia: [],
	listaGraficoGeracaoEnergia: [],
	listaGraficoFaturamentoEnergia: [],
	listaGraficoPorProduto: [],
	listaGraficoPorAgrupador: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'CONSULTA_FILTRO':
            return { ...state, filtro: action.payload };
		case 'CONSULTA_CLIENTE_LISTADO':
            return { ...state, listaCliente: action.payload.data };
		case 'CONSULTA_UNIDADE_LISTADA':
            return { ...state, listaUnidade: action.payload.data };
		case 'CONSULTA_PRODUTO_LISTADA':
            return { ...state, listaProduto: action.payload.data };
        case 'CONSULTA_GRAFICO_CONSUMO_ENERGIA_LISTADO':
            return { ...state, listaGraficoConsumoEnergia: action.payload.data };
        case 'CONSULTA_GRAFICO_GERACAO_ENERGIA_LISTADO':
            return { ...state, listaGraficoGeracaoEnergia: action.payload.data };
		case 'CONSULTA_GRAFICO_FATURAMENTO_ENERGIA_LISTADO':
            return { ...state, listaGraficoFaturamentoEnergia: action.payload.data };
		case 'CONSULTA_GRAFICO_POR_PRODUTO_LISTADO':
            return { ...state, listaGraficoPorProduto: action.payload.data };
		case 'CONSULTA_GRAFICO_POR_AGRUPADOR_LISTADO':
            return { ...state, listaGraficoPorAgrupador: action.payload.data };
        default:
            return state;
    }
}
