import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getLista, modoAlteracao, modoExclusao } from '../configuracao/configuracaoActions';

class ConfiguracaoLista extends Component {

    componentWillMount() {
        this.props.getLista();
    }

    renderRows() {
		let configuracao = this.props.lista ? this.props.lista : null;
		if (configuracao) {
	        return (
	            <tr key={configuracao.id}>
	                <td>{String(configuracao.valor_hora_tecnica || 0).replace('.', ',')}</td>
	                <td>
	                    <button className='btn btn-warning' onClick={() => this.props.modoAlteracao({
							...configuracao,
							valor_hora_tecnica: String(configuracao.valor_hora_tecnica || 0).replace('.', ',')
						})}>
	                        <i className='fa fa-pencil'></i>
	                    </button>
	                    <button className='btn btn-danger' onClick={() => this.props.modoExclusao({
							...configuracao,
							valor_hora_tecnica: String(configuracao.valor_hora_tecnica || 0).replace('.', ',')
						})}>
	                        <i className='fa fa-trash-o'></i>
	                    </button>
	                </td>
	            </tr>
	        );
		} else {
			return null;
		}
    }

    render() {
        return (
            <div>
                <table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
                            <th>Hora Técnica (R$)</th>
                            <th className='table-actions'>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => ({lista: state.configuracao.lista});
const mapDispatchToProps = dispatch => bindActionCreators({getLista, modoAlteracao, modoExclusao}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ConfiguracaoLista);
