import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import Tabs from '../common/tab/tabs';
import TabsHeader from '../common/tab/tabsHeader';
import TabsContent from '../common/tab/tabsContent';
import TabHeader from '../common/tab/tabHeader';
import TabContent from '../common/tab/tabContent';
import { incluir, alterar, alterarSenha, excluir, modoLista, modoInclusao, getListaCliente, getListaUsuarioTipo } from './usuarioActions';

import Lista from './usuarioLista';
import Form from './usuarioForm';

class Usuario extends Component {

    componentWillMount() {
        this.props.modoLista();
        this.props.getListaUsuarioTipo();
        this.props.getListaCliente();
    }

    render() {
        return (
            <div>
                <ContentHeader title='Usuário' small='Cadastro' />
                <Content>
                    <Tabs>
                        <TabsContent>
                            <TabContent id='modoLista'>
                                <button className='btn btn-success' onClick={() => this.props.modoInclusao()}>
                                    <i className='fa fa-plus'> Adicionar</i>
                                </button>
                                <Lista />
                            </TabContent>
                            <TabContent id='modoInclusao'>
                                <Form onSubmit={this.props.incluir}
                                    submitLabel='Incluir' submitClass='primary' />
                            </TabContent>
                            <TabContent id='modoAlteracao'>
                                <Form onSubmit={this.props.alterar}
                                    submitLabel='Alterar' submitClass='info' />
                            </TabContent>
                            <TabContent id='modoAlteracaoSenha'>
                                <Form alterarSenha onSubmit={this.props.alterarSenha} readOnly='readOnly'
                                    submitLabel='Alterar Senha' submitClass='info' />
                            </TabContent>
                            <TabContent id='modoExclusao'>
                                <Form onSubmit={this.props.excluir}
                                    submitLabel='Excluir' submitClass='danger' readOnly='readOnly' />
                            </TabContent>
                        </TabsContent>
                    </Tabs>
                </Content>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ incluir, alterar, alterarSenha, excluir, modoLista, modoInclusao, getListaCliente, getListaUsuarioTipo }, dispatch);
export default connect(null, mapDispatchToProps)(Usuario);
