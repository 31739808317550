import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import consts from '../consts';

export function login(values) {
	return submit(values, `${consts.OAPI_URL}/usuario/login`);
}

export function signup(values) {
	return submit(values, `${consts.OAPI_URL}/signup`);
}

function submit(values, url) {
	return dispatch => {
		axios.post(url, values)
		.then(resp => {
			dispatch([
				{type: 'USER_FETCHED', payload: resp.data}
			]);
		})
		.catch(e => {
			if (e.response.status == 400) {
				if (e.response.data instanceof Array) {
					e.response.data.forEach(retorno => {
						toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
					});
				} else {
					toastr.error('Erro', e.response.data);
				}
			} else {
				toastr.error('Erro', 'Erro ao realizar Operação!!');
			}
		});
	}
}

export function logout() {
	return {type: 'TOKEN_VALIDATED', payload: false};
}

export function validateToken(token) {
	return dispatch => {
		if (token) {
			axios.post(`${consts.OAPI_URL}/usuario/validateToken`, {token})
			.then(resp => {
				dispatch({type: 'TOKEN_VALIDATED', payload: resp.data.valid})
			})
			.catch(e => dispatch({type: 'TOKEN_VALIDATED', payload: false}))
		} else {
			dispatch({type: 'TOKEN_VALIDATED', payload: false});
		}
	}
}
