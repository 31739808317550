import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { getDataAtual, getDataFormatada, getData } from '../utils/dataUtils';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import cookie from 'react-cookies'
import { getLista, getListaCliente, getListaUnidade, modoAlteracao, modoExclusao, modoFaturaItemInclusao } from './faturaActions';

import Select from '../common/form/select';
import FormatarValor from '../common/form/formatarValor';

class FaturaLista extends Component {

    componentWillMount() {
		this.props.getLista(cookie.load('faturaListaUnidade') || 0);
		this.props.getListaCliente();
		this.props.getListaUnidade(cookie.load('faturaListaCliente') || 0);
    }

    renderRows() {
        const lista = this.props.lista || [];
        return lista.map(fatura => (
            <tr key={fatura.id}>
                <td>{fatura.nome_cliente}</td>
				<td>{fatura.nome_unidade}</td>
				<td>{fatura.competencia_formatada.charAt(0).toUpperCase() + fatura.competencia_formatada.slice(1)}</td>
				<td>{getDataFormatada(fatura.data_inicio_consumo, 'DD/MM/YYYY')}</td>
				<td>{getDataFormatada(fatura.data_fim_consumo, 'DD/MM/YYYY')}</td>
				<td>{fatura.data_vencimento ? getDataFormatada(fatura.data_vencimento, 'DD/MM/YYYY') : ''}</td>
				<td>{fatura.concessionaria}</td>
				<td align='right'>
					<FormatarValor valor={fatura.valor_fatura} displayType={'text'} casas={2} />
				</td>
                <td>
                    <button className='btn btn-warning' onClick={() => this.props.modoAlteracao({
						...fatura,
						data_inicio_consumo: getDataFormatada(fatura.data_inicio_consumo, 'DD/MM/YYYY'),
						data_fim_consumo: getDataFormatada(fatura.data_fim_consumo, 'DD/MM/YYYY'),
						data_vencimento: fatura.data_vencimento ? getDataFormatada(fatura.data_vencimento, 'DD/MM/YYYY') : '',
					})}>
                        <i className='fa fa-pencil'></i>
                    </button>
					<button className='btn btn-success' onClick={() => this.props.modoFaturaItemInclusao(
						{
							id_fatura: fatura.id,
							id_unidade: fatura.id_unidade,
							id_cliente: fatura.id_cliente,
							nome_unidade: fatura.nome_unidade,
							nome_cliente: fatura.nome_cliente,
							competencia: fatura.competencia,
							data_inicio_consumo: getDataFormatada(fatura.data_inicio_consumo, 'DD/MM/YYYY'),
							data_fim_consumo: getDataFormatada(fatura.data_fim_consumo, 'DD/MM/YYYY'),
							data_vencimento: fatura.data_vencimento ? getDataFormatada(fatura.data_vencimento, 'DD/MM/YYYY') : '',
							concessionaria: fatura.concessionaria,
							resumo: fatura.resumo
						})}>
                        <i className='fa fa-reorder'></i>
                    </button>
                    <button className='btn btn-danger' onClick={() => this.props.modoExclusao(
						{
							...fatura,
							data_inicio_consumo: getDataFormatada(fatura.data_inicio_consumo, 'YYYY/DD/MM'),
							data_fim_consumo: getDataFormatada(fatura.data_fim_consumo, 'DD/MM/YYYY'),
							data_vencimento: fatura.data_vencimento ? getDataFormatada(fatura.data_vencimento, 'DD/MM/YYYY') : '',
							concessionaria: fatura.concessionaria,
							resumo: fatura.resumo
						})}>
                        <i className='fa fa-trash-o'></i>
                    </button>
                </td>
            </tr>
        ));
    }

    render() {
        return (
            <div>
				<form role='form' onSubmit={() => null}>
					<div className='box-body'>
						<div className='row'>
							<div className='col-sm-12'>
								<Field name='id_cliente' component={Select}
									onChange={(e) => {
										this.props.getLista(0);
										cookie.save('faturaListaCliente', e.target.value, { path: '/' });
										this.props.getListaUnidade(e.target.value);
									}}
									value={cookie.load('faturaListaCliente') || 0}
									options={this.props.listaCliente}
									label='Cliente' cols='12 6' placeholder='Selecione o Cliente' />
							</div>
							<div className='col-sm-12'>
								<Field name='id_unidade' component={Select}
									onChange={(e) => {
										cookie.save('faturaListaUnidade', e.target.value, { path: '/' });
										this.props.getLista(e.target.value);
									}}
									value={cookie.load('faturaListaUnidade') || 0}
									options={this.props.listaUnidade}
									label='Unidade' cols='12 6' placeholder='Selecione a Unidade' />
							</div>
						</div>
					</div>
				</form>
                <table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
                            <th>Cliente</th>
							<th>Unidade</th>
							<th>Competência</th>
							<th>Início Consumo</th>
							<th>Fim Consumo</th>
							<th>Vencimento</th>
							<th>Concessionária</th>
							<th style={{ textAlign: 'right' }}>Valor</th>
                            <th className='table-actions'>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

FaturaLista = reduxForm({form: 'faturaLista', destroyOnUnmount: false})(FaturaLista);
const mapStateToProps = state => ({
	lista: state.fatura.lista,
	listaCliente: state.fatura.listaCliente,
	listaUnidade: state.fatura.listaUnidade
});
const mapDispatchToProps = dispatch => bindActionCreators({getLista, getListaCliente, getListaUnidade, modoAlteracao, modoExclusao, modoFaturaItemInclusao}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FaturaLista);
