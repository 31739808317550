import React from 'react'
import MenuItem from './menuItem'
import MenuTree from './menuTree'

export default props => (
    <ul className='sidebar-menu'>
        <MenuItem path='/' label='Dashboard' icon='dashboard' />
        <MenuTree label='Cadastro' icon='edit'>
			<MenuItem path='configuracao' label='Configuração' icon='cog' />
            <MenuItem path='usuarioTipo' label='Tipo de Usuário' icon='vcard-o' />
            <MenuItem path='usuario' label='Usuário' icon='user-circle-o' />
            <MenuItem path='grandeza' label='Grandeza' icon='flash' />
			<MenuItem path='tipoProduto' label='Tipo de Produto' icon='cog' />
			<MenuItem path='produto' label='Produto' icon='barcode' />
            <MenuItem path='cliente' label='Cliente' icon='building' />
        </MenuTree>
		<MenuItem path='ordemServico' label='Ordem de Serviço' icon='file-text-o' />
		<MenuItem path='demanda' label='Demanda' icon='bolt' />
		<MenuItem path='fatura' label='Fatura' icon='list-alt' />
        <MenuItem path='unidadeCarga' label='Cargas' icon='list-ol' />
		<MenuItem path='relatorioFatura' label='Relatório de Faturas' icon='dollar' />
		<MenuItem path='relatorioDemanda' label='Relatório de Demandas' icon='bars' />
		<MenuItem path='consulta' label='Consultas' icon='line-chart' />
        <MenuItem path='simuladorFatura' label='Simulador de Fatura' icon='file-pdf-o' />
    </ul>
)
