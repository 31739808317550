const INITIAL_STATE = {lista: [], listaUnidade: [], listaEstado: [], listaCidade: [], estadoSelecionado: 0 };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CLIENTE_LISTADO':
            return { ...state, lista: action.payload.data };
		case 'UNIDADE_LISTADA':
			return { ...state, listaUnidade: action.payload.data };
		case 'CLIENTE_ESTADO_LISTADO':
            return { ...state, listaEstado: action.payload.data };
		case 'CLIENTE_CIDADE_LISTADO':
            return { ...state, listaCidade: action.payload.data };
		case 'ESTADO_VALUE_CHANGED':
            return { ...state, estadoSelecionado: action.payload };
        default:
            return state;
    }
}
