import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import { initForm, modoLista, getListaGrandeza, changeValueGrandeza } from './tipoProdutoActions';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import ColorSelector from '../common/form/colorSeletor';
import Row from '../common/layout/row';

class TipoProdutoForm extends Component {

	componentWillMount() {
        this.props.getListaGrandeza();
    }

    render() {
        const { handleSubmit, readOnly } = this.props;
		const listaGrandeza = this.props.listaGrandeza || [];
        return (
            <form role='form' onSubmit={handleSubmit}>
                <div className='box-body'>
					<Row>
	                    <Field name='descricao' component={LabelAndInput} readOnly={readOnly}
	                        label='Descrição' cols='12 12' placeholder='Informe a Descrição' />
					</Row>
					<Row>
						<Field name='id_grandeza' component={Select}
	                        onChange={this.props.changeValueGrandeza}
	                        value={this.props.GrandezaSelecionada}
	                        options={listaGrandeza} readOnly={readOnly}
	                        label='Grandeza' cols='12 4' placeholder='Selecione a Grandeza' />
					</Row>
					<Row>
						<Field
							name='cor'
							component={ColorSelector}
							label='Cor'
							cols='12 10 6 4'
							readOnly={readOnly}
							color={this.props.formularioValues.cor || '#ffffff'}
							complete={(color) => {
								this.props.initForm({ ...this.props.formularioValues, cor: color.hex });
							}} />
					</Row>
                </div>
                <div className='box-footer'>
                    <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                        {this.props.submitLabel}
                    </button>
                    <button type='button' className='btn btn-default'
                        onClick={this.props.modoLista}>Cancelar</button>
                </div>
            </form>
        );
    }
}

TipoProdutoForm = reduxForm({form: 'tipoProdutoForm', destroyOnUnmount: false})(TipoProdutoForm);
const mapStateToProps = state => ({
	listaGrandeza: state.tipoProduto.listaGrandeza,
    grandezaSelecionado: state.tipoProduto.grandezaelecionado,
	formularioValues: getFormValues('tipoProdutoForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ initForm, modoLista, getListaGrandeza, changeValueGrandeza }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TipoProdutoForm);
