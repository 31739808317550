import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCorFonte } from '../utils/corUtils';
import { getLista, modoAlteracao, modoExclusao } from './tipoProdutoActions';

class TipoProdutoLista extends Component {

    componentWillMount() {
        this.props.getLista();
    }

    renderRows() {
        const lista = this.props.lista || [];
        return lista.map(tipoProduto => (
            <tr key={tipoProduto.id} style={{ background: tipoProduto.cor, color: getCorFonte(tipoProduto.cor) }}>
                <td>{tipoProduto.descricao}</td>
				<td>{tipoProduto.grandeza_descricao}</td>
                <td>
                    <button className='btn btn-warning' onClick={() => this.props.modoAlteracao( { ...tipoProduto, compoe_custo: tipoProduto.compoe_custo ? 1 : 2 })}>
                        <i className='fa fa-pencil'></i>
                    </button>
                    <button className='btn btn-danger' onClick={() => this.props.modoExclusao({ ...tipoProduto, compoe_custo: tipoProduto.compoe_custo ? 1 : 2 })}>
                        <i className='fa fa-trash-o'></i>
                    </button>
                </td>
            </tr>
        ));
    }

    render() {
        return (
            <div>
                <table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
                            <th>Descrição</th>
							<th>Grandeza</th>
                            <th className='table-actions'>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => ({lista: state.tipoProduto.lista});
const mapDispatchToProps = dispatch => bindActionCreators({getLista, modoAlteracao, modoExclusao}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TipoProdutoLista);
