import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';

const INITIAL_VALUES = { descricao: '', id_grandeza: null };

export function initForm(registro = {}, form = 'tipoProdutoForm') {
	return [
		initialize(form, registro)
	];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/tipoproduto`);
    return {
        type: 'TIPO_PRODUTO_LISTADA',
        payload: request
    };
}

export function getListaGrandeza() {
    const request = axios.get(`${consts.API_URL}/grandeza/listarselect`);
    return {
        type: 'GRANDEZA_SELECT_LISTADA',
        payload: request
    };
}

export function changeValueGrandeza(e) {
    return {
        type: 'GRANDEZA_VALUE_CHANGED',
        payload: e.target.value
    }
}

export function incluir(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/tipoproduto`, { ...registro, })
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function alterar(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/tipoproduto`, { ...registro })
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/tipoproduto?id=${registro.id}`)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function modoLista() {
    return [
        showTabs('modoLista'),
        selectTab('modoLista'),
        getLista(),
        initialize('tipoProdutoForm', INITIAL_VALUES)
    ];
}

export function modoInclusao(tipoProduto) {
    return [
        showTabs('modoInclusao'),
        selectTab('modoInclusao'),
        initialize('tipoProdutoForm', tipoProduto)
    ];
}

export function modoAlteracao(tipoProduto) {
    return [
        showTabs('modoAlteracao'),
        selectTab('modoAlteracao'),
        initialize('tipoProdutoForm', tipoProduto)
    ];
}

export function modoExclusao(tipoProduto) {
    return [
        showTabs('modoExclusao'),
        selectTab('modoExclusao'),
        initialize('tipoProdutoForm', tipoProduto)
    ];
}
