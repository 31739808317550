import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import moment from 'moment';

const INITIAL_VALUES = {descricao: ''};

export function initForm(registro = {}, form = 'ordemServicoForm') {
	return [
		initialize(form, registro)
	];
}

export function setFiltro(filtro) {
    return {
        type: 'ORDEM_SERVICO_FILTRO',
        payload: filtro
    };
}

export function getLista() {

	return (dispatch, getState) => {
        axios.get(`${consts.API_URL}/ordemServico?data_inicial=${getState().ordemServico.filtro.getDataBanco(getState().ordemServico.filtro.dataInicial)}&data_final=${getState().ordemServico.filtro.getDataBanco(getState().ordemServico.filtro.dataFinal)}`)
        .then(resp => {
            dispatch({
				type: 'ORDEM_SERVICO_LISTADA',
		        payload: resp
			});
        }).catch(ex => {

		});
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarselect`);
    return {
        type: 'ORDEM_SERVICO_CLIENTE_LISTADO',
        payload: request
    };
}

export function getListaUnidade(id_cliente) {
    const request = axios.get(`${consts.API_URL}/unidade/listarselect?id_cliente=${id_cliente}`);
    return {
        type: 'ORDEM_SERVICO_UNIDADE_LISTADA',
        payload: request
    };
}

export function getConfiguracao() {
    const request = axios.get(`${consts.API_URL}/configuracao`);
    return {
        type: 'ORDEM_SERVICO_CONFIGURACAO',
        payload: request
    };
}

export function getOrdemServicoImpressao(ordemServico) {
    const request = axios.get(`${consts.API_URL}/ordemServico/getOrdemServicoImpressao?id=${ordemServico.id}`);
    return {
        type: 'ORDEM_SERVICO_IMPRESSAO_LISTADO',
        payload: request
    };
}

export function incluir(registro) {

	let data_agendamento = registro.data_agendamento ? registro.data_agendamento.split('/')[2] + '/' + registro.data_agendamento.split('/')[1] + '/' + registro.data_agendamento.split('/')[0] : null;

    return async (dispatch, getState) => {
		await axios.post(`${consts.API_URL}/ordemServico`, {
			...registro,
			data_agendamento: data_agendamento,
			valor_hora_tecnica: getState().ordemServico.configuracao.valor_hora_tecnica
		}).then(async resp => {
			let unidades = registro.unidades || [];
			for (var i = 0; i < unidades.length; i++) {
				let unidade = unidades[i];
				if (unidade.id && !unidade.ativo) {
					await axios.delete(`${consts.API_URL}/ordemServicoUnidade?id=${unidade.id}`);
				} else if (!unidade.id) {
					await axios.post(`${consts.API_URL}/ordemServicoUnidade`, {
						id_ordem_servico: resp.data,
						id_unidade: unidade.id_unidade
					});
				}
			}

			toastr.success('Sucesso', 'Operação Realizada com sucesso.');
			dispatch(modoLista());
		}).catch(e => {
			if (e.response.status == 400) {
				e.response.data.forEach(retorno => {
					toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
				});
			} else {
				toastr.error('Erro', 'Erro ao realizar Operação!!');
			}
		});
    };
}

export function alterar(registro) {
	let data_agendamento = registro.data_agendamento ? registro.data_agendamento.split('/')[2] + '/' + registro.data_agendamento.split('/')[1] + '/' + registro.data_agendamento.split('/')[0] : null;

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/ordemServico`, {
			...registro,
			data_agendamento: data_agendamento,
			valor_hora_tecnica: getState().ordemServico.configuracao.valor_hora_tecnica
		}).then(async resp => {
			let unidades = registro.unidades || [];
			for (var i = 0; i < unidades.length; i++) {
				let unidade = unidades[i];
				if (unidade.id && !unidade.ativo) {
					await axios.delete(`${consts.API_URL}/ordemServicoUnidade?id=${unidade.id}`);
				} else if (!unidade.id) {
					await axios.post(`${consts.API_URL}/ordemServicoUnidade`, {
						id_ordem_servico: registro.id,
						id_unidade: unidade.id_unidade
					});
				}
			}

			toastr.success('Sucesso', 'Operação Realizada com sucesso.');
			dispatch(modoLista());
		}).catch(e => {
			if (e.response.status == 400) {
				e.response.data.forEach(retorno => {
					toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
				});
			} else {
				toastr.error('Erro', 'Erro ao realizar Operação!!');
			}
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/ordemServico?id=${registro.id}`)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function modoLista() {
    return [
        showTabs('modoLista'),
        selectTab('modoLista'),
        getLista(),
		getConfiguracao(),
        initialize('ordemServicoForm', INITIAL_VALUES)
    ];
}

export function modoInclusao(ordemServico) {
    return [
        showTabs('modoInclusao'),
        selectTab('modoInclusao'),
        initialize('ordemServicoForm', ordemServico),
		getConfiguracao(),
		getListaCliente(),
		getListaUnidade(0)
    ];
}

export function modoAlteracao(ordemServico) {
    return [
        showTabs('modoAlteracao'),
        selectTab('modoAlteracao'),
        initialize('ordemServicoForm', ordemServico),
		getConfiguracao(),
		getListaCliente(),
		getListaUnidade(ordemServico.id_cliente)
    ];
}

export function modoExclusao(ordemServico) {
    return [
        showTabs('modoExclusao'),
        selectTab('modoExclusao'),
        initialize('ordemServicoForm', ordemServico),
		getConfiguracao(),
		getListaCliente(),
		getListaUnidade(ordemServico.id_cliente)
    ];
}

export function modoImpressao(ordemServico) {
    return [
		showTabs('modoImpressao'),
        selectTab('modoImpressao'),
        initialize('ordemServicoForm', ordemServico),
		getConfiguracao(),
		getListaCliente(),
		getListaUnidade(ordemServico.id_cliente),
		getOrdemServicoImpressao(ordemServico)
    ];
}
