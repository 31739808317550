import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';

const INITIAL_VALUES = { nome: '' };

export function getLista(id_unidade) {
    const request = axios.get(`${consts.API_URL}/demanda?id_unidade=${id_unidade}`);
    return {
        type: 'DEMANDA_LISTADA',
        payload: request
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarselect`);
    return {
        type: 'DEMANDA_CLIENTE_LISTADO',
        payload: request
    };
}

export function getListaUnidade(id_cliente) {
    const request = axios.get(`${consts.API_URL}/unidade/listarselect?id_cliente=${id_cliente}`);
    return {
        type: 'DEMANDA_UNIDADE_LISTADA',
        payload: request
    };
}

export function getListaDemandaItem(demandaItem) {
    const request = axios.get(`${consts.API_URL}/demandaitem?id_demanda=${demandaItem.id_demanda}`);
    return {
        type: 'DEMANDA_ITEM_LISTADA',
        payload: request
    };
}

export function incluir(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/demanda`, registro)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function alterar(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/demanda`, registro)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/demanda?id=${registro.id}`)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function adicionarDemandaItem(registro) {
	return dispatch => {
        axios.post(`${consts.API_URL}/demandaitem`, registro)
            .then(resp => {
                // toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                // dispatch(modoDemandaItemInclusao({ id_demanda: registro.id_demanda, nome_demanda: registro.nome_demanda }));
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function removerDemandaItem(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/demandaitem?id=${registro.id}`)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoDemandaItemInclusao({ id_demanda: registro.id_demanda, nome_demanda: registro.nome_demanda }));
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function modoLista() {
    return [
        showTabs('modoLista'),
        selectTab('modoLista'),
        initialize('demandaForm', INITIAL_VALUES)
    ];
}

export function modoInclusao(demanda) {
    return [
        showTabs('modoInclusao'),
        selectTab('modoInclusao'),
		getListaCliente(),
		getListaUnidade(0),
        initialize('demandaForm', demanda)
    ];
}

export function modoAlteracao(demanda) {
    return [
        showTabs('modoAlteracao'),
        selectTab('modoAlteracao'),
		getListaCliente(),
		getListaUnidade(demanda.id_cliente),
        initialize('demandaForm', demanda)
    ];
}

export function modoExclusao(demanda) {
    return [
        showTabs('modoExclusao'),
        selectTab('modoExclusao'),
		getListaCliente(),
		getListaUnidade(demanda.id_cliente),
        initialize('demandaForm', demanda)
    ];
}

export function modoDemandaItemInclusao(demandaItem) {
    return [
        showTabs('modoDemandaItemInclusao'),
        selectTab('modoDemandaItemInclusao'),
		getListaDemandaItem({ id_demanda: demandaItem.id_demanda }),
        initialize('demandaForm', demandaItem)
    ];
}

export function modoDemandaItemAlteracao(demandaItem) {
    return [
        showTabs('modoDemandaItemAlteracao'),
        selectTab('modoDemandaItemAlteracao'),
		getListaDemandaItem({ id_demanda: demandaItem.id_demanda }),
        initialize('demandaForm', demandaItem)
    ];
}
