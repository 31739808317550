import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';

const INITIAL_VALUES = { nome: '' };

export function getLista(id_unidade) {
    const request = axios.get(`${consts.API_URL}/fatura/listarSimulacaoFatura?id_unidade=${id_unidade}`);
    return {
        type: 'SIMULACAO_FATURA_LISTADA',
        payload: request
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarselect`);
    return {
        type: 'SIMULACAO_FATURA_CLIENTE_LISTADO',
        payload: request
    };
}

export function getListaUnidade(id_cliente) {
    const request = axios.get(`${consts.API_URL}/unidade/listarselect?id_cliente=${id_cliente}`);
    return {
        type: 'SIMULACAO_FATURA_UNIDADE_LISTADA',
        payload: request
    };
}

export function modoLista() {
    return [
        getLista(0),
        getListaCliente(),
		getListaUnidade(0),
        showTabs('modoLista'),
        selectTab('modoLista'),
    ];
}
