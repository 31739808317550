const INITIAL_STATE = {
	lista: [], listaTipoProduto: [], tipoProdutoSelecionado: 0,
	compoeGraficoConsumoSelecionado: false, compoeDemandaLidaSelecionado: false, compoeGeracaoEnergiaSelecionado: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'PRODUTO_LISTADO':
            return { ...state, lista: action.payload.data };
        case 'TIPO_PRODUTO_SELECT_LISTADO':
            return { ...state, listaTipoProduto: action.payload.data };
        case 'TIPO_PRODUTO_VALUE_CHANGED':
            return { ...state, tipoProdutoSelecionado: action.payload };
		case 'COMPOE_GRAFICO_CONSUMO_VALUE_CHANGED':
            return { ...state, compoeGraficoConsumoSelecionado: action.payload };
		case 'COMPOE_DEMANDA_LIDA_VALUE_CHANGED':
            return { ...state, compoeDemandaLidaSelecionado: action.payload };
		case 'COMPOE_GERACAO_ENERGIA_VALUE_CHANGED':
            return { ...state, compoeGeracaoEnergiaSelecionado: action.payload };
        default:
            return state;
    }
}
