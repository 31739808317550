import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getLista, modoAlteracao, modoExclusao, modoUnidadeInclusao } from './clienteActions';

class ClienteLista extends Component {

    componentWillMount() {
        this.props.getLista();
    }

    renderRows() {
        const lista = this.props.lista || [];
        return lista.map(cliente => (
            <tr key={cliente.id}>
                <td>{cliente.nome}</td>
                <td>
                    <button className='btn btn-warning' onClick={() => this.props.modoAlteracao(cliente)}>
                        <i className='fa fa-pencil'></i>
                    </button>
					<button className='btn btn-success' onClick={() => this.props.modoUnidadeInclusao({ id_cliente: cliente.id, nome_cliente: cliente.nome })}>
                        <i className='fa fa-reorder'></i>
                    </button>
                    <button className='btn btn-danger' onClick={() => this.props.modoExclusao(cliente)}>
                        <i className='fa fa-trash-o'></i>
                    </button>
                </td>
            </tr>
        ));
    }

    render() {
        return (
            <div>
                <table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th className='table-actions'>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => ({lista: state.cliente.lista});
const mapDispatchToProps = dispatch => bindActionCreators({getLista, modoAlteracao, modoExclusao, modoUnidadeInclusao}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClienteLista);
