import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import { modoLista, getListaCidade } from './clienteActions';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import ListaUnidades from './clienteListaUnidades';
import Select from '../common/form/select';

class ClienteForm extends Component {

    render() {
		const { handleSubmit, readOnly, listaUnidadeTipo, listaEstado, listaCidade } = this.props;
		
		let listaHorario = [];
		let dataHoraBase = new Date('2023/01/01 00:00');
		for (let i = 0; i < 48; i++) {

			listaHorario.push({
				id: `${String(dataHoraBase.getHours()).padStart(2, '0')}:${String(dataHoraBase.getMinutes()).padStart(2, '0')}`,
				valor: `${String(dataHoraBase.getHours()).padStart(2, '0')}:${String(dataHoraBase.getMinutes()).padStart(2, '0')}`
			});
			
			dataHoraBase.setMinutes(dataHoraBase.getMinutes() + 30);
		}

		if (this.props.modoUnidade) {
	        return (
	            <form role='form' onSubmit={handleSubmit}>

					<button type='button' className='btn btn-default'
						onClick={this.props.modoLista}>Voltar</button>

	                <div className='box-body'>
	                    <Field name='nome_cliente' component={LabelAndInput} readOnly={readOnly}
	                        label='Nome' cols='12 12' placeholder='Informe o Nome' />

						<Field name='uc' component={LabelAndInput}
	                        label='UC' cols='6 3' placeholder='Informe a Unidde Consumidora' />

						<Field name='razao_social' component={LabelAndInput}
							label='Razão Social' cols='12 12' placeholder='Informe a Razão Social' />

						<Field name='nome_fantasia' component={LabelAndInput}
							label='Nome Fantasia' cols='12 12' placeholder='Informe o Nome Fantasia' />

						<Field name='cpf_cnpj' component={LabelAndInput}
							label='CPF/CNPJ' cols='12 12' placeholder='Informe o CPF para cliente física e o CNPJ para jurídica' />

						<Field name='email' component={LabelAndInput}
							label='Email' cols='12 12' placeholder='Informe o Email' />

						<Field name='logradouro' component={LabelAndInput}
						label='Logradouro' cols='12 6' placeholder='Informe o Logradouro' />

						<Field name='numero' component={LabelAndInput}
						label='Número' cols='12 2' placeholder='Informe o Número' />

						<Field name='complemento' component={LabelAndInput}
						label='Complemento' cols='12 4' placeholder='Informe o Complemento' />

						<Field name='bairro' component={LabelAndInput}
						label='Bairro' cols='12 6' placeholder='Informe o Bairro' />

						<Field name='cep' component={LabelAndInput}
						label='CEP' cols='12 2' placeholder='Informe o CEP' />

						<div className='row'>
							<div className='col-sm-12'>
								<Field name='id_estado' component={Select}
									onChange={(e) => {
										this.props.getListaCidade(e.target.value);
									}}
									value={this.props.estadoSelecionado}
									options={listaEstado} readOnly={readOnly}
									label='Estado' cols='12 4' placeholder='Selecione o Estado' />

								<Field name='id_cidade' component={Select}
									onChange={this.props.changeValueCidade}
									value={this.props.cidadeSelecionado}
									options={listaCidade}
									label='Cidade' cols='12 4' placeholder='Selecione a Cidade' />
							</div>
						</div>

						<Field name='classificacao' component={LabelAndInput}
							label='Classificação' cols='12 6' placeholder='Informe o Classificação' />

						<Field name='observacao' component={LabelAndTextarea} rows={5}
							label='Observação' cols='12 12' placeholder='Informe Observações' />
							
							
						<div className='row'>
							<Field name='periodo_ponta_inicio' component={Select}
								value={this.props.periodo_ponta_inicio}
								options={listaHorario}
								label='Horário Ponta Início' cols='12 4' placeholder='Selecione o horário' />
								
							<Field name='periodo_ponta_fim' component={Select}
								value={this.props.periodo_ponta_fim}
								options={listaHorario}
								label='Horário Ponta Fim' cols='12 4' placeholder='Selecione o horário' />

						</div>
						<div className='row'>							
							<Field name='periodo_fora_ponta_inicio' component={Select}
								value={this.props.periodo_fora_ponta_inicio}
								options={listaHorario}
								label='Horário Fora Ponta Início' cols='12 4' placeholder='Selecione o horário' />
								
							<Field name='periodo_fora_ponta_fim' component={Select}
								value={this.props.periodo_fora_ponta_fim}
								options={listaHorario}
								label='Horário Fora Ponta Fim' cols='12 4' placeholder='Selecione o horário' />
						</div>
						<div className='row'>							
							<Field name='periodo_reservado_inicio' component={Select}
								value={this.props.periodo_reservado_inicio}
								options={listaHorario}
								label='Horário Reservado Início' cols='12 4' placeholder='Selecione o horário' />
								
							<Field name='periodo_reservado_fim' component={Select}
								value={this.props.periodo_reservado_fim}
								options={listaHorario}
								label='Horário Reservado Fim' cols='12 4' placeholder='Selecione o horário' />
						</div>
						
	                </div>
	                <div className='box-footer'>
	                    <button type='submit' className={`btn btn-${this.props.submitClass}`}>
	                        {this.props.submitLabel}
	                    </button>
	                </div>

					<ListaUnidades />

	            </form>
	        );
		} else {
			return (
	            <form role='form' onSubmit={handleSubmit}>
	                <div className='box-body'>
	                    <Field name='nome' component={LabelAndInput} readOnly={readOnly}
	                        label='Nome' cols='12 12' placeholder='Informe o Nome' />
	                </div>
	                <div className='box-footer'>
	                    <button type='submit' className={`btn btn-${this.props.submitClass}`}>
	                        {this.props.submitLabel}
	                    </button>
	                    <button type='button' className='btn btn-default'
	                        onClick={this.props.modoLista}>Cancelar</button>
	                </div>
	            </form>
	        );
		}
    }
}

ClienteForm = reduxForm({form: 'clienteForm', destroyOnUnmount: false})(ClienteForm);
const mapStateToProps = state => ({
	listaUnidadeTipo: state.cliente.listaUnidadeTipo,
	listaEstado: state.cliente.listaEstado,
	listaCidade: state.cliente.listaCidade,
    estadoSelecionado: state.cliente.estadoSelecionado
});
const mapDispatchToProps = dispatch => bindActionCreators({ modoLista, getListaCidade }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClienteForm);
