import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import { modoLista, getListaUnidade, modoImpressao, copiarMesAnterior } from './faturaActions';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import ListaFaturaItens from './faturaListaFaturaItens';
import ListaFaturaAgrupador from './faturaListaAgrupador';
import FaturaPdf from './faturaPdf';
import Select from '../common/form/select';

class FaturaForm extends Component {

    render() {
        const { handleSubmit, readOnly, listaFaturaItemTipo, listaCliente, listaUnidade, listaProduto, modoImpressao } = this.props;

		if (this.props.modoFaturaItem) {
			return (
				<form role='form' onSubmit={handleSubmit}>

					<button type='button' className='btn btn-default'
						onClick={this.props.modoLista}>Voltar</button>
					<button type='button' className='btn btn-warning'
						onClick={() => this.props.copiarMesAnterior({ id: this.props.id })}>
						<i className='fa fa-clone'> Copiar Mês Anterior</i>
					</button>
					<button type='button' className='btn btn-primary'
						onClick={() => modoImpressao({ id: this.props.id, id_unidade: this.props.id_unidade, telaAnterior: 'visualizacao' })}>
						<i className='fa fa-file-pdf-o'> Gerar Resumo</i>
					</button>

					<div className='box-body'>
						<div className='row'>
							<div className='col-sm-12'>
								<Field name='nome_cliente' component={LabelAndInput} readOnly={readOnly}
									label='Cliente' cols='12 6' placeholder='Informe o Cliente' />
								</div>
							<div className='col-sm-12'>
								<Field name='nome_unidade' component={LabelAndInput} readOnly={readOnly}
									label='Unidade' cols='12 6' placeholder='Informe a Unidade' />
							</div>
						</div>
						<div className='row'>
							<div className='col-sm-12'>
								<Field name='competencia' type="month" component={LabelAndInput} readOnly={readOnly}
									label='Competência' cols='12 4 3' placeholder='Informe a Competência' />
							</div>
						</div>
						<div className='row'>
							<div className='col-sm-12'>
								<Field name='data_vencimento' component={LabelAndInput} readOnly={readOnly}
									label='Data de Vencimento' cols='12 4 3' placeholder='Informe a Data de Vencimento' />
							</div>
						</div>
						<Field name='data_inicio_consumo' component={LabelAndInput} readOnly={readOnly}
							label='Data do Início do Consumo' cols='12 4 3' placeholder='Informe a Data do Início do Consumo' />
						<Field name='data_fim_consumo' component={LabelAndInput} readOnly={readOnly}
							label='Data do Fim do Consumo' cols='12 4 3' placeholder='Informe a Data do Fim do Consumo' />
						<div className='row'>
							<div className='col-sm-12'>
								<Field name='concessionaria' component={LabelAndInput} readOnly={readOnly}
									label='Concessionária' cols='12 6' placeholder='Informe a Concessionária' />
							</div>
						</div>
						<Field name='resumo' component={LabelAndTextarea} rows={5} readOnly={readOnly}
							label='Resumo' cols='12 12' placeholder='Informe Resumo' />
						<div className='row'>
							<div className='col-sm-12'>
								<Field name='id_produto' component={Select} readOnly={readOnly}
									onChange={this.props.changeValueProduto}
									value={this.props.produtoSelecionado}
									options={listaProduto}
									label='Produto' cols='12 6' placeholder='Selecione o Produto' />
							</div>
						</div>
					</div>
					<div className='box-footer'>
						<button type='submit' className={`btn btn-${this.props.submitClass}`}>
							{this.props.submitLabel}
						</button>
					</div>

					<ListaFaturaItens />

					<div className='row'>
						<div className='col-sm-6'>
							<ListaFaturaAgrupador />
						</div>
					</div>

				</form>
			);
		} else {
			return (
				<div>
					{this.props.formularioValues && this.props.formularioValues.id ? (
						<button type='button' className='btn btn-primary'
							onClick={() => modoImpressao({ id: this.props.formularioValues.id, id_unidade: this.props.formularioValues.id_unidade, telaAnterior: 'alteracao' })}>
							<i className='fa fa-file-pdf-o'> Gerar Resumo</i>
						</button>
					) : null}
		            <form role='form' onSubmit={handleSubmit}>
		                <div className='box-body'>
							<div className='row'>
								<div className='col-sm-12'>
									<Field name='id_cliente' component={Select} readOnly={readOnly}
										onChange={(e) => {
											this.props.getListaUnidade(e.target.value);
										}}
										value={this.props.clienteSelecionado}
										options={listaCliente} readOnly={readOnly}
										label='Cliente' cols='12 6' placeholder='Selecione o Cliente' />
								</div>
								<div className='col-sm-12'>
									<Field name='id_unidade' component={Select} readOnly={readOnly}
										onChange={this.props.changeValueUnidade}
										value={this.props.unidadeSelecionado}
										options={listaUnidade}
										label='Unidade' cols='12 6' placeholder='Selecione a Unidade' />
								</div>
							</div>
							<div className='row'>
								<div className='col-sm-12'>
									<Field name='competencia' type="month" component={LabelAndInput} readOnly={readOnly}
										label='Competência' cols='12 4 3' placeholder='Informe a Competência' />
								</div>
							</div>
							<div className='row'>
								<div className='col-sm-12'>
									{this.props.submitLabel == 'Incluir' ?
									<Field name='data_vencimento' type="date" component={LabelAndInput} readOnly={readOnly}
										label='Data de Vencimento' cols='12 4 3' placeholder='Informe a Data de Vencimento' />
									:
									<Field name='data_vencimento' component={LabelAndInput} readOnly={readOnly}
										label='Data de Vencimento' cols='12 4 3' placeholder='Informe a Data de Vencimento' />
									}
								</div>
							</div>
							{this.props.submitLabel == 'Incluir' ?
							<Field name='data_inicio_consumo' type="date" component={LabelAndInput} readOnly={readOnly}
								label='Data do Início do Consumo' cols='12 4 3' placeholder='Informe a Data do Início do Consumo' />
							:
							<Field name='data_inicio_consumo' component={LabelAndInput} readOnly={readOnly}
								label='Data do Início do Consumo' cols='12 4 3' placeholder='Informe a Data do Início do Consumo' />
							}
							{this.props.submitLabel == 'Incluir' ?
							<Field name='data_fim_consumo' type="date" component={LabelAndInput} readOnly={readOnly}
								label='Data do Fim do Consumo' cols='12 4 3' placeholder='Informe a Data do Fim do Consumo' />
							:
							<Field name='data_fim_consumo' component={LabelAndInput} readOnly={readOnly}
								label='Data do Fim do Consumo' cols='12 4 3' placeholder='Informe a Data do Fim do Consumo' />
							}
							<div className='row'>
								<div className='col-sm-12'>
									<Field name='concessionaria' component={LabelAndInput} readOnly={readOnly}
				                        label='Concessionária' cols='12 6' placeholder='Informe a Concessionária' />
								</div>
							</div>
							<Field name='resumo' component={LabelAndTextarea} rows={5}
								label='Resumo' cols='12 12' placeholder='Informe Resumo' />
						</div>
		                <div className='box-footer'>
		                    <button type='submit' className={`btn btn-${this.props.submitClass}`}>
		                        {this.props.submitLabel}
		                    </button>
		                    <button type='button' className='btn btn-default'
		                        onClick={this.props.modoLista}>Cancelar</button>
		                </div>

						{this.props.formularioValues && this.props.formularioValues.id ? (
							<ListaFaturaItens />
						) : null}
						{this.props.formularioValues && this.props.formularioValues.id ? (
							<div className='row'>
								<div className='col-sm-6'>
									<ListaFaturaAgrupador />
								</div>
							</div>
						) : null}

		            </form>
				</div>
	        );
		}
    }
}

FaturaForm = reduxForm({form: 'faturaForm', destroyOnUnmount: false})(FaturaForm);
const mapStateToProps = state => ({
	id: state.fatura.id,
	id_unidade: state.fatura.id_unidade,
	listaFaturaItemTipo: state.fatura.listaFaturaItemTipo,
	listaCliente: state.fatura.listaCliente,
	listaUnidade: state.fatura.listaUnidade,
    clienteSelecionado: state.fatura.clienteSelecionado,
	listaProduto: state.fatura.listaProduto,
	formularioValues: getFormValues('faturaForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ modoLista, getListaUnidade, modoImpressao, copiarMesAnterior }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FaturaForm);
