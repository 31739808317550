import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactToPdf from 'react-to-pdf';
import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import Tabs from '../common/tab/tabs';
import TabsHeader from '../common/tab/tabsHeader';
import TabsContent from '../common/tab/tabsContent';
import TabHeader from '../common/tab/tabHeader';
import TabContent from '../common/tab/tabContent';
import { LineChart, PieChart, BarChart } from 'react-chartkick'
import Chart from "react-google-charts";
import Grid from '../common/layout/grid'
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import MultiSelect from 'react-select';
import { modoLista, setFiltro, getListaCliente, getListaUnidade, getListaProduto, listarGraficoConsumoEnergia, listarGraficoFaturamentoEnergia } from './consultaActions';
import imagemLogo from '../assets/images/vetor_logo.jpg';


class Consulta extends Component {

    componentWillMount() {
        this.props.modoLista();
    }

	componentDidMount() {
		let contentWrapper = document.getElementById('id-content-wrapper');
		contentWrapper.classList.remove('content-wrapper');
	}

	componentWillUnmount() {
		let contentWrapper = document.getElementById('id-content-wrapper');
		contentWrapper.classList.add('content-wrapper');
	}

	render() {
		return (this.montarPdf());
	}

	montarPdf() {

		let cores = [
			'#845EC2', '#e57373', '#42a5f5', '#0097a7', '#ad1457', '#8c9eff', '#1976d2','#D65DB1', '#FF6F91', '#FF9671', '#FFC75F', '#F9F871', '#e57373', '#42a5f5',
			'#0097a7', '#ad1457', '#8c9eff', '#1976d2', '#00acc1', '#66bb6a', '#69f0ae', '#880e4f', '#f06292', '#00e5ff', '#00bfa5', '#b2dfdb', '#0091ea', '#ccff90',
			'#1565c0', '#b2dfdb', '#845EC2', '#D65DB1', '#FF6F91', '#FF9671', '#FFC75F', '#F9F871', '#e57373', '#42a5f5', '#0097a7', '#ad1457', '#8c9eff', '#1976d2',
			'#00acc1', '#66bb6a', '#69f0ae', '#880e4f', '#f06292', '#00e5ff', '#00bfa5', '#b2dfdb', '#0091ea', '#ccff90', '#1565c0', '#b2dfdb'
		];

		const ref = React.createRef();

		const container = {
			marginLeft: 210
		};

		const principal = {
			width: 793,
			height: 1122,
			paddingLeft: 40,
			paddingRight: 40,
			paddingTop: 25
		};

		const campoApresentacao = {
			paddingTop: 0,
			paddingBottom: 0,
			marginTop: 0,
			marginBottom: 0
		}

		const borda = {
			borderStyle: 'solid',
			borderTopWidth: 1,
			borderBottomWidth: 0,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderColor: '#000'
		};

		const bordaCampoApresentacao = {
			borderStyle: 'solid',
			borderTopWidth: 0,
			borderBottomWidth: 0,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderColor: '#000',
			paddingTop: 0,
			paddingBottom: 0,
			marginTop: 0,
			marginBottom: 0
		};

		const campoApresentacaoLabel = {
			marginBottom: 0,
			marginTop: 0,
			paddingTop: 0,
			paddingBottom: 0,
		}

		const bordaSeparacaoGrafico = {
			borderStyle: 'solid',
			borderTopWidth: 0,
			borderBottomWidth: 0,
			borderLeftWidth: 0,
			borderRightWidth: 1,
			borderColor: '#000'
		};

		const bordaFinal = {
			borderStyle: 'solid',
			borderTopWidth: 1,
			borderBottomWidth: 1,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderColor: '#000'
		};

        return (
			<div style={container}>
				<ReactToPdf targetRef={ref} filename={`${this.props.listaCliente.filter(item => item.id == this.props.filtro.id_cliente)[0] ? this.props.listaCliente.filter(item => item.id == this.props.filtro.id_cliente)[0].valor : ''} - ${this.props.listaUnidade.filter(item => item.id == this.props.filtro.id_unidade)[0] ? this.props.listaUnidade.filter(item => item.id == this.props.filtro.id_unidade)[0].valor : ''}.pdf`} options={{ orientation: 'portrait' }}>
					{({toPdf}) => (
						<div>
							<button style={{ marginTop: 55, marginLeft: 25 }} type='button' className='btn btn-primary' onClick={toPdf}>
								<i className='fa fa-download'> Baixar</i>
							</button>
						</div>
					)}
				</ReactToPdf>

                <Content>
                    <Tabs>
                        <TabsContent>
                            <TabContent id='modoLista'>
								<div className='box-body'>
									<div className='row'>
										<Select
											label='Cliente'
											placeholder='Selecione o Cliente'
											cols='12 4 3'
											value={this.props.filtro.id_cliente}
											options={this.props.listaCliente}
											onChange={data => {
												this.props.setFiltro({
													...this.props.filtro,
													id_cliente: data.target.value,
													id_unidade: 0
												});
												this.props.modoLista();
											}} />
										<Select
											label='Unidade'
											placeholder='Selecione a Unidade'
											cols='12 4 3'
											value={this.props.filtro.id_unidade}
											options={this.props.filtro.id_cliente ? this.props.listaUnidade.filter(unidade => unidade.id_cliente == this.props.filtro.id_cliente) : []}
											onChange={data => {
												this.props.setFiltro({
													...this.props.filtro,
													id_unidade: data.target.value
												});
												this.props.modoLista();
											}} />

										<LabelAndInputMask
											label='Data Inicial'
											placeholder='Informe a Data Inicial'
											mask={'99/99/9999'}
											cols='12 4 3'
											value={this.props.filtro.dataInicial}
											onChange={data => {
												this.props.setFiltro({
													...this.props.filtro,
													dataInicial: data.target.value
												});
												this.props.modoLista();
											}} />
										<LabelAndInputMask
											label='Data Final'
											placeholder='Informe a Data Final'
											mask={'99/99/9999'}
											cols='12 4 3'
											value={this.props.filtro.dataFinal}
											onChange={data => {
												this.props.setFiltro({
													...this.props.filtro,
													dataFinal: data.target.value
												});
												this.props.modoLista();
											}} />

										<Grid cols='12 12 12'>
											<div className='form-group'>
												<label>Produtos</label>
												<MultiSelect
													defaultValue={this.props.filtro.id_produtos
														? this.props.filtro.id_produtos.map(item => {
															return {
																value: item,
																label: this.props.listaProduto.filter(produto => produto.id == item)[0].valor
															};
														})
														: []}
													isMulti
													name='produtos'
													options={this.props.listaProduto.filter(item => {
														if (this.props.filtro.id_produtos && this.props.filtro.id_produtos.length == 4) {
															return this.props.filtro.id_produtos.filter(idProduto => idProduto == item.id).length > 0;
														} else {
															return true;
														}
													}).map(item => ({ value: item.id, label: item.valor }))}
													className='basic-multi-select'
													classNamePrefix='Selecione os produtos'
													placeholder='Selecione os produtos'
													onChange={data => {
														this.props.setFiltro({
															...this.props.filtro,
															id_produtos: data ? data.map(item => item.value) : null
														});
														this.props.modoLista();
													}}
												/>
											</div>
										</Grid>
									</div>
									<br />

									<div style={principal} ref={ref}>
										<div className='row' style={borda}>
											<Grid cols='5'>
												<img src={imagemLogo} style={{ height: 105.88, width: 300 }} />
											</Grid>
											<Grid cols='7'>
												<div className='row' style={{ textAlign: 'center', marginTop: 10 }}>
													<label>Vetor</label> CNPJ 25.155.189/0001-12
												</div>
												<div className='row' style={{ textAlign: 'center' }}>
													Linha 2 Oeste, RS 522, Km 02 - Dis. Industrial - Barreiro
												</div>
												<div className='row' style={{ textAlign: 'center' }}>
													Ijuí - RS CEP 98700-000
												</div>
												<div className='row' style={{ textAlign: 'center' }}>
													Fone (55) 3308-1881 <label>vetoreletrotecnica@gmail.com</label>
												</div>
											</Grid>
										</div>
										<div className='row' style={borda}>
											<Grid cols='8' style={campoApresentacao}>
												<label style={campoApresentacaoLabel}>Nome / Razão Social: </label> {this.props.listaCliente.filter(item => item.id == this.props.filtro.id_cliente)[0] ? this.props.listaCliente.filter(item => item.id == this.props.filtro.id_cliente)[0].valor : ''}
											</Grid>
											<Grid cols='4' style={campoApresentacao}>
												<label style={campoApresentacaoLabel}>UC: </label> {this.props.listaUnidade.filter(item => item.id == this.props.filtro.id_unidade)[0] ? this.props.listaUnidade.filter(item => item.id == this.props.filtro.id_unidade)[0].valor : ''}
											</Grid>
											<Grid cols='12' style={campoApresentacao}>
												<label style={campoApresentacaoLabel}>Período: </label> {this.props.filtro.dataInicial} {this.props.filtro.dataInicial && this.props.filtro.dataFinal ? '-' : ''} {this.props.filtro.dataFinal}
											</Grid>
										</div>
										<div className='row' style={borda}>
										</div>

										{(!this.props.filtro.id_produtos || this.props.filtro.id_produtos.length == 0) || this.props.listaGraficoConsumoEnergia.length > 4 ? null : this.props.filtro.id_produtos.map(id_produto => {

											let produto = this.props.listaProduto.filter(produto => produto.id == id_produto)[0];
											let produtoConsumo = this.props.listaGraficoConsumoEnergia.filter(produto => produto.id_produto == id_produto)[0];
											let produtoFaturamento = this.props.listaGraficoFaturamentoEnergia.filter(produto => produto.id_produto == id_produto)[0];

											let totalConsumo = 0;
											if (produtoConsumo && produtoConsumo.itens) {
												produtoConsumo.itens.forEach((item, i) => {
													if (i > 0) {
														item.forEach((linha, j) => {
															if (j % 2 != 0) {
																totalConsumo += parseFloat(linha);
															}
														});
													}
												});
											}

											let totalFaturamento = 0;
											if (produtoFaturamento && produtoFaturamento.itens) {
												produtoFaturamento.itens.forEach((item, i) => {
													if (i > 0) {
														item.forEach((linha, j) => {
															if (j % 2 != 0) {
																totalFaturamento += parseFloat(linha);
															}
														});
													}
												});
											}

											return (
												<div className='row'>
													<Grid cols='6'>
														<div className='row' style={{ textAlign: 'center' }}>
															<label>{produto.valor} ({totalConsumo.toFixed(2).replace('.', ',')} KW/h)</label>
															{produtoConsumo ?
															<Chart
																width={'104%'}
																height={200}
																chartType="ColumnChart"
																loader={<div>Carregando</div>}
																data={produtoConsumo ? produtoConsumo.itens : null}
																options={{
																	chartArea: { width: '80%', height: '75%' },
																	legend: {
																		position: 'top',
																		alignment: 'center'
																	}
																}}
																numberFormat={{
																	column: 1,
																	options: {
																		fractionDigits: 0
																	}
																}}
																legendToggle
															/> : null}
														</div>
													</Grid>

													<Grid cols='6'>
														<div className='row' style={{ textAlign: 'center' }}>
															<label>{produto.valor} (R$ {totalFaturamento.toFixed(2).replace('.', ',')})</label>
															{produtoFaturamento ?
															<Chart
																width={'104%'}
																height={200}
																chartType="ColumnChart"
																loader={<div>Carregando</div>}
																data={produtoFaturamento ? produtoFaturamento.itens : null}
																options={{
																	chartArea: { width: '80%', height: '75%' },
																	legend: {
																		position: 'top',
																		alignment: 'center'
																	}
																}}
																numberFormat={{
																	column: 1,
																	options: {
																		fractionDigits: 0
																	}
																}}
																legendToggle
															/> : null}
														</div>
													</Grid>
												</div>
											);
										})}
									</div>
								</div>
                            </TabContent>
                        </TabsContent>
                    </Tabs>
                </Content>
            </div>
        )
    }
}

const mapStateToProps = state => ({
	filtro: state.consulta.filtro,
	listaCliente: state.consulta.listaCliente,
	listaUnidade: state.consulta.listaUnidade,
	listaProduto: state.consulta.listaProduto,
	listaGraficoConsumoEnergia: state.consulta.listaGraficoConsumoEnergia,
	listaGraficoFaturamentoEnergia: state.consulta.listaGraficoFaturamentoEnergia
});
const mapDispatchToProps = dispatch => bindActionCreators({ setFiltro, getListaCliente, getListaUnidade, getListaProduto, modoLista, listarGraficoConsumoEnergia, listarGraficoFaturamentoEnergia }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Consulta);
