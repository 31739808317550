import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormatarValor from '../common/form/formatarValor';
import { getCorFonte } from '../utils/corUtils';
import { modoAlteracao, modoExclusao, modoFaturaItemAlteracao, alterarFaturaItem, removerFaturaItem } from './faturaActions';

class FaturaListaFaturaItens extends Component {

	state = { lista: [] };

    renderRows() {

		if (this.state.lista.length != this.props.listaFaturaItem.length) {
			this.setState({ lista: this.props.listaFaturaItem });
		}

        return this.state.lista.map(faturaItem => (
            <tr key={faturaItem.id} style={{ background: faturaItem.cor, color: getCorFonte(faturaItem.cor) }}>
				<td>{faturaItem.produto_descricao}</td>
				<td>{faturaItem.grandeza_descricao}</td>
				<td align='left' style={{ color: '#000' }}>
					<FormatarValor style={{ width: 80 }} valor={faturaItem.valor_unitario} displayType={'none'} casas={6} onChange={(event) => {
						faturaItem.valor_unitario = event.target.value || '0';
						faturaItem.valor_total = String((parseFloat(faturaItem.valor_unitario.replace(',', '.') || 0)
							* parseFloat(faturaItem.quantidade.replace(',', '.') || 0))
							+ parseFloat(faturaItem.impostos.replace(',', '.') || 0)).replace('.', ',');
						this.props.alterarFaturaItem({ ...faturaItem });

						this.setState({ lista: this.state.lista.map(item => item.id == faturaItem.id ? faturaItem : item)});
					}} />
				</td>
				<td align='left' style={{ color: '#000' }}>
					<FormatarValor style={{ width: 80 }} valor={faturaItem.quantidade} displayType={'none'} casas={2} onChange={(event) => {
						faturaItem.quantidade = event.target.value || '0';
						faturaItem.valor_total = String((parseFloat(faturaItem.valor_unitario.replace(',', '.') || 0)
							* parseFloat(faturaItem.quantidade.replace(',', '.') || 0))
							+ parseFloat(faturaItem.impostos.replace(',', '.') || 0)).replace('.', ',');
						this.props.alterarFaturaItem({ ...faturaItem });

						this.setState({ lista: this.state.lista.map(item => item.id == faturaItem.id ? faturaItem : item)});
					}} />
				</td>
				<td align='left' style={{ color: '#000' }}>
					<FormatarValor style={{ width: 80 }} valor={faturaItem.impostos} displayType={'none'} casas={2} onChange={(event) => {
						faturaItem.impostos = event.target.value || '0';
						faturaItem.valor_total = String((parseFloat(faturaItem.valor_unitario.replace(',', '.') || 0)
							* parseFloat(faturaItem.quantidade.replace(',', '.') || 0))
							+ parseFloat(faturaItem.impostos.replace(',', '.') || 0)).replace('.', ',');
						this.props.alterarFaturaItem({ ...faturaItem });

						this.setState({ lista: this.state.lista.map(item => item.id == faturaItem.id ? faturaItem : item)});
					}} />
				</td>
				<td align='left' style={{ color: '#000' }}>
					<FormatarValor style={{ width: 80 }} valor={faturaItem.valor_total} displayType={'none'} casas={2} onChange={(event) => {
						faturaItem.valor_total = event.target.value || 0;
						this.props.alterarFaturaItem({ ...faturaItem });

						this.setState({ lista: this.state.lista.map(item => item.id == faturaItem.id ? faturaItem : item)});
					}} />
				</td>
				<td style={{ textAlign: 'center' }}>
					<button type='button' className='btn btn-danger' onClick={() => this.props.removerFaturaItem({ ...faturaItem })}>
						<i className='fa fa-trash-o'></i>
					</button>
				</td>
            </tr>
        ));
    }

    render() {
        return (
            <div>
                <table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
							<th style={{ textAlign: 'left', width: 600 }}>Produto</th>
							<th style={{ textAlign: 'left', width: 150 }}>Grandeza</th>
							<th style={{ textAlign: 'left', width: 85 }}>Vlr Unitário</th>
							<th style={{ textAlign: 'left', width: 85 }}>Quantidade</th>
							<th style={{ textAlign: 'left', width: 85 }}>Imposto</th>
							<th style={{ textAlign: 'left', width: 85 }}>Valor Total</th>
							<th style={{ textAlign: 'center', width: 50 }}>Remover</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => ({listaFaturaItem: state.fatura.listaFaturaItem});
const mapDispatchToProps = dispatch => bindActionCreators({ modoAlteracao, modoExclusao, modoFaturaItemAlteracao, alterarFaturaItem, removerFaturaItem}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FaturaListaFaturaItens);
