const INITIAL_STATE = { lista: [], listaUsuarioTipo: [], usuarioTipoSelecionado: 0, listaCliente: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'USUARIO_LISTADO':
            return { ...state, lista: action.payload.data };
        case 'USUARIO_TIPO_SELECT_LISTADO':
            return { ...state, listaUsuarioTipo: action.payload.data };
        case 'USUARIO_CLIENTE_SELECT_LISTADO':
            return { ...state, listaCliente: action.payload.data };
        case 'USUARIO_TIPO_VALUE_CHANGED':
            return { ...state, usuarioTipoSelecionado: action.payload };
        default:
            return state;
    }
}
