import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { getLista, modoAlteracao, modoExclusao } from '../ordemServico/ordemServicoActions';

class OrdemServicoLista extends Component {

    componentWillMount() {
        this.props.getLista();
    }

    renderRows() {
        const lista = this.props.lista || [];
        return lista.map(ordemServico => {

			let hora = parseInt(ordemServico.tempo / 60);
			let minuto = ordemServico.tempo - (hora * 60);

			return (
	            <tr key={ordemServico.id} style={{ background: ordemServico.tempo > 0 && ordemServico.valor_cobrado > 0 ? '#c8e6c9' : null }}>
	                <td>{ordemServico.numero}</td>
					<td>{moment(new Date(ordemServico.data_agendamento)).format('DD/MM/YYYY')}</td>
					<td>{ordemServico.nome_cliente}</td>
					<td>{ordemServico.descricao}</td>
					<td>
	                    <button className='btn btn-warning' onClick={() => this.props.modoAlteracao({
							...ordemServico,
							data_agendamento: moment(new Date(ordemServico.data_agendamento)).format('DD/MM/YYYY')
						})}>
	                        <i className='fa fa-pencil'></i>
	                    </button>
	                    <button className='btn btn-danger' onClick={() => this.props.modoExclusao({
							...ordemServico,
							data_agendamento: moment(new Date(ordemServico.data_agendamento)).format('DD/MM/YYYY')
						})}>
	                        <i className='fa fa-trash-o'></i>
	                    </button>
	                </td>
	            </tr>
			);
        });
    }

    render() {
        return (
            <div>
                <table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
                            <th>Número</th>
							<th>Data</th>
							<th>Cliente</th>
							<th>Descrição</th>
                            <th className='table-actions'>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => ({lista: state.ordemServico.lista});
const mapDispatchToProps = dispatch => bindActionCreators({getLista, modoAlteracao, modoExclusao}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrdemServicoLista);
