import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';

const INITIAL_VALUES = {descricao: '', id_usuario_tipo: null};

export function getLista() {
    const request = axios.get(`${consts.API_URL}/usuario`);
    return {
        type: 'USUARIO_LISTADO',
        payload: request
    };
}

export function getListaUsuarioTipo() {
    const request = axios.get(`${consts.API_URL}/usuariotipo/listarselect`);
    return {
        type: 'USUARIO_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarselect`);
    return {
        type: 'USUARIO_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function changeValueUsuarioTipo(e) {
    return {
        type: 'USUARIO_TIPO_VALUE_CHANGED',
        payload: e.target.value
    }
}

export function incluir(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/usuario`, registro)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    if (e.response.data instanceof Array) {
                        e.response.data.forEach(retorno => {
                            toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                        });
                    } else {
                        toastr.error('Erro', e.response.data);
                    }
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function alterar(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/usuario`, registro)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    if (e.response.data instanceof Array) {
                        e.response.data.forEach(retorno => {
                            toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                        });
                    } else {
                        toastr.error('Erro', e.response.data);
                    }
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function alterarSenha(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/usuario/alterarsenha`, registro)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                console.log(e);
                if (e.response.status == 400) {
                    if (e.response.data instanceof Array) {
                        e.response.data.forEach(retorno => {
                            toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                        });
                    } else {
                        toastr.error('Erro', e.response.data);
                    }
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/usuario?id=${registro.id}`)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function modoLista() {
    return [
        showTabs('modoLista'),
        selectTab('modoLista'),
        getLista(),
        initialize('usuarioForm', INITIAL_VALUES)
    ];
}

export function modoInclusao(usuario) {
    return [
        showTabs('modoInclusao'),
        selectTab('modoInclusao'),
        initialize('usuarioForm', usuario)
    ];
}

export function modoAlteracao(usuario) {
    return [
        showTabs('modoAlteracao'),
        selectTab('modoAlteracao'),
        initialize('usuarioForm', usuario)
    ];
}

export function modoAlteracaoSenha(usuario) {
    return [
        showTabs('modoAlteracaoSenha'),
        selectTab('modoAlteracaoSenha'),
        initialize('usuarioForm', { ...usuario, senha: '' })
    ];
}

export function modoExclusao(usuario) {
    return [
        showTabs('modoExclusao'),
        selectTab('modoExclusao'),
        initialize('usuarioForm', usuario)
    ];
}
