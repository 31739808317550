import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';

const INITIAL_VALUES = {descricao: ''};

export function getLista() {
    const request = axios.get(`${consts.API_URL}/configuracao`);
    return {
        type: 'CONFIGURACAO_LISTADA',
        payload: request
    };
}

export function incluir(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/configuracao`, registro)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function alterar(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/configuracao`, registro)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/configuracao?id=${registro.id}`)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function modoLista() {
    return [
        showTabs('modoLista'),
        selectTab('modoLista'),
        getLista(),
        initialize('configuracaoForm', INITIAL_VALUES)
    ];
}

export function modoInclusao(configuracao) {
    return [
        showTabs('modoInclusao'),
        selectTab('modoInclusao'),
        initialize('configuracaoForm', configuracao)
    ];
}

export function modoAlteracao(configuracao) {
    return [
        showTabs('modoAlteracao'),
        selectTab('modoAlteracao'),
        initialize('configuracaoForm', configuracao)
    ];
}

export function modoExclusao(configuracao) {
    return [
        showTabs('modoExclusao'),
        selectTab('modoExclusao'),
        initialize('configuracaoForm', configuracao)
    ];
}
