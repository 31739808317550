const INITIAL_STATE = {
	lista: [],
	listaFaturaItem: [],
	listaFaturaAgrupador: [],
	listaCliente: [],
	listaUnidade: [],
	listaProduto: [],
	listarGraficoConsumo: [],
	listarGraficoHistoricoFaturamento: [],
	listaGraficoHistoricoGeracaoEnergia: [],
	clienteSelecionado: 0,
	id_fatura: null,
	id_unidade: null,
	id_cliente: null,
	nome_unidade: '',
	competencia: null,
	data_inicio_consumo: null,
	data_fim_consumo: null,
	data_vencimento: null,
	id: null,
	nome_cliente: '',
	uc: '',
	endereco: '',
	consumo: '',
	cidade: '',
	cep: '',
	cpf_cnpj: '',
	mes_faturado: '',
	concessionaria: '',
	vencimento: '',
	classificacao: '',
	demanda_contratada: 0,
	valor_fatura: 0,
	apelido: '',
	resumo: '',
	telaAnterior: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'FATURA_LISTADA':
            return { ...state, lista: action.payload.data };
		case 'FATURA_CARREGADA':
			return {
				...state,
				id_unidade: action.payload.data.id_unidade,
				id_cliente: action.payload.data.id_cliente,
				nome_unidade: action.payload.data.nome_unidade,
				competencia: action.payload.data.competencia,
				data_inicio_consumo: action.payload.data.data_inicio_consumo,
				data_fim_consumo: action.payload.data.data_fim_consumo,
				data_vencimento: action.payload.data.data_vencimento,
				id: action.payload.data.id,
				nome_cliente: action.payload.data.nome_cliente,
				uc: action.payload.data.uc,
				endereco: action.payload.data.endereco,
				consumo: action.payload.data.consumo,
				cidade: action.payload.data.cidade,
				cep: action.payload.data.cep,
				cpf_cnpj: action.payload.data.cpf_cnpj,
				mes_faturado: action.payload.data.mes_faturado,
				concessionaria: action.payload.data.concessionaria,
				vencimento: action.payload.data.vencimento,
				classificacao: action.payload.data.classificacao,
				demanda_contratada: action.payload.data.demanda_contratada,
				valor_fatura: action.payload.data.valor_fatura,
				apelido: action.payload.data.apelido,
				resumo: action.payload.data.resumo
			};
		case 'FATURA_IMPRESSAO_TELA_ANTERIOR':
			return { ...state, telaAnterior: action.payload };
		case 'FATURA_ITEM_LISTADA':
			return { ...state, listaFaturaItem: action.payload.data };
		case 'FATURA_AGRUPADOR_LISTADO':
			return { ...state, listaFaturaAgrupador: action.payload.data };
		case 'FATURA_CLIENTE_LISTADO':
            return { ...state, listaCliente: action.payload.data };
		case 'FATURA_UNIDADE_LISTADA':
            return { ...state, listaUnidade: action.payload.data };
		case 'FATURA_PRODUTO_LISTADO':
            return { ...state, listaProduto: action.payload.data };
		case 'FATURA_GRAFICO_CONSUMO_LISTADO':
			return { ...state, listarGraficoConsumo: action.payload.data };
		case 'FATURA_GRAFICO_HISTORICO_FATURAMENTO_LISTADO':
			return { ...state, listarGraficoHistoricoFaturamento: action.payload.data };
		case 'FATURA_GRAFICO_GERACAO_ENERGIA_LISTADO':
			return { ...state, listaGraficoHistoricoGeracaoEnergia: action.payload.data };
		case 'CLIENTE_VALUE_CHANGED':
            return { ...state, clienteSelecionado: action.payload };
        default:
            return state;
    }
}
