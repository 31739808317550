const INITIAL_STATE = {lista: [], listaGrandeza: [], grandezaSelecionada: null };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'TIPO_PRODUTO_LISTADA':
            return { ...state, lista: action.payload.data };
		case 'GRANDEZA_SELECT_LISTADA':
            return { ...state, listaGrandeza: action.payload.data };
        case 'GRANDEZA_VALUE_CHANGED':
            return { ...state, grandezaSelecionado: action.payload };
        default:
            return state;
    }
}
