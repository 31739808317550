import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getLista, modoAlteracao, modoExclusao } from './usuarioTipoActions';

class UsuarioTipoLista extends Component {

    componentWillMount() {
        this.props.getLista();
    }

    renderRows() {
        const lista = this.props.lista || [];
        return lista.map(usuarioTipo => (
            <tr key={usuarioTipo.id}>
                <td>{usuarioTipo.descricao}</td>
                <td>
                    <button className='btn btn-warning' onClick={() => this.props.modoAlteracao(usuarioTipo)}>
                        <i className='fa fa-pencil'></i>
                    </button>
                    <button className='btn btn-danger' onClick={() => this.props.modoExclusao(usuarioTipo)}>
                        <i className='fa fa-trash-o'></i>
                    </button>
                </td>
            </tr>
        ));
    }

    render() {
        return (
            <div>
                <table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
                            <th>Descrição</th>
                            <th className='table-actions'>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => ({lista: state.usuarioTipo.lista});
const mapDispatchToProps = dispatch => bindActionCreators({getLista, modoAlteracao, modoExclusao}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioTipoLista);
