import React from 'react';
import If from '../operator/if';

export default props => (
	<If test={!props.hide}>
		<div className="form-group input-group">
			<input {...props.input}
				className='form-control input-group'
				placeholder={props.placeholder}
				readOnly={props.readOnly}
				type={props.type} />

			{props.onClickIcon &&
			<div class='input-group-addon'
				onClick={props.onClickIcon} >
				<i class={`fa fa-${props.icon}`}></i>
			</div>}
			{/*<span className={`glyphicon glyphicon-${props.icon} form-control-feedback`} 
					onClick={props.onClickIcon}></span>*/}
		</div>
	</If>
);
