import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import { modoLista } from './grandezaActions';
import LabelAndInput from '../common/form/labelAndInput';

class GrandezaForm extends Component {

    render() {
        const { handleSubmit, readOnly } = this.props;
        return (
            <form role='form' onSubmit={handleSubmit}>
                <div className='box-body'>
                    <Field name='descricao' component={LabelAndInput} readOnly={readOnly}
                        label='Descrição' cols='12 4' placeholder='Informe a Descrição' />
                </div>
                <div className='box-footer'>
                    <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                        {this.props.submitLabel}
                    </button>
                    <button type='button' className='btn btn-default'
                        onClick={this.props.modoLista}>Cancelar</button>
                </div>
            </form>
        );
    }
}

GrandezaForm = reduxForm({form: 'grandezaForm', destroyOnUnmount: false})(GrandezaForm);
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({ modoLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(GrandezaForm);
