import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormatarValor from '../common/form/formatarValor';
import { getCorFonte } from '../utils/corUtils';
import { modoAlteracao, modoExclusao, modoFaturaItemAlteracao, alterarFaturaItem, removerFaturaItem } from './faturaActions';

class FaturaListaAgrupador extends Component {

    renderRows() {
        return this.props.listaFaturaAgrupador.map(agrupador => (
            <tr key={agrupador.id} style={{ background: agrupador.cor, color: getCorFonte(agrupador.cor) }}>
				<td>{agrupador.descricao}</td>
				<td align='right'>
					<FormatarValor valor={agrupador.valor > 0 ? agrupador.valor : agrupador.quantidade} displayType={'text'} casas={2} />
				</td>
            </tr>
        ));
    }

	renderTotal() {
		let total = parseFloat(0);
		this.props.listaFaturaAgrupador.forEach(agrupador => {
			total += parseFloat(agrupador.valor);
		});

		return (
			<tr>
				<td style={{ fontWeight: 'bold' }}>Total</td>
				<td style={{ fontWeight: 'bold' }} align='right'>
					<FormatarValor valor={total} displayType={'text'} casas={2} />
				</td>
			</tr>
		);
	}

    render() {
        return (
            <div>
				<table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
							<th>Tipo de Produto</th>
							<th style={{ textAlign: 'right' }}>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
						{this.renderTotal()}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => ({ listaFaturaAgrupador: state.fatura.listaFaturaAgrupador });
const mapDispatchToProps = dispatch => bindActionCreators({ modoAlteracao, modoExclusao, modoFaturaItemAlteracao, alterarFaturaItem, removerFaturaItem}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FaturaListaAgrupador);
