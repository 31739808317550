import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';

const INITIAL_VALUES = {};

export function setFiltro(filtro) {
    return {
        type: 'CONSULTA_FILTRO',
        payload: filtro
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarselect`);
    return {
        type: 'CONSULTA_CLIENTE_LISTADO',
        payload: request
    };
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarselect`);
    return {
        type: 'CONSULTA_UNIDADE_LISTADA',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarselect`);
    return {
        type: 'CONSULTA_PRODUTO_LISTADA',
        payload: request
    };
}

export function listarGraficoConsumoEnergia() {

	return (dispatch, getState) => {

		let id_produtos = null;
		if (getState().consulta.filtro.id_produtos && getState().consulta.filtro.id_produtos.length > 0) {
			id_produtos = '(';
			getState().consulta.filtro.id_produtos.forEach((item, i) => {
				id_produtos += i == 0 ? String(item) : ',' + String(item);
			});
			id_produtos += ')';
		}

		axios.get(`${consts.API_URL}/fatura/listarGraficoConsumoEnergia
			?id_cliente=${getState().consulta.filtro.id_cliente != 0 ? getState().consulta.filtro.id_cliente : null}
			&id_unidade=${getState().consulta.filtro.id_unidade != 0 ? getState().consulta.filtro.id_unidade : null}
			&id_produtos=${id_produtos}
			&data_inicial=${getState().consulta.filtro.getDataBanco(getState().consulta.filtro.dataInicial)}
			&data_final=${getState().consulta.filtro.getDataBanco(getState().consulta.filtro.dataFinal)}`)
		.then(resp => {
            dispatch({
				type: 'CONSULTA_GRAFICO_CONSUMO_ENERGIA_LISTADO',
		        payload: resp
			});
        }).catch(ex => {

		});
    };
}

export function listarGraficoFaturamentoEnergia() {

	return (dispatch, getState) => {

		let id_produtos = null;
		if (getState().consulta.filtro.id_produtos && getState().consulta.filtro.id_produtos.length > 0) {
			id_produtos = '(';
			getState().consulta.filtro.id_produtos.forEach((item, i) => {
				id_produtos += i == 0 ? String(item) : ',' + String(item);
			});
			id_produtos += ')';
		}

		axios.get(`${consts.API_URL}/fatura/listarGraficoFaturamentoEnergia
			?id_cliente=${getState().consulta.filtro.id_cliente != 0 ? getState().consulta.filtro.id_cliente : null}
			&id_unidade=${getState().consulta.filtro.id_unidade != 0 ? getState().consulta.filtro.id_unidade : null}
			&id_produtos=${id_produtos}
			&data_inicial=${getState().consulta.filtro.getDataBanco(getState().consulta.filtro.dataInicial)}
			&data_final=${getState().consulta.filtro.getDataBanco(getState().consulta.filtro.dataFinal)}`)
		.then(resp => {
            dispatch({
				type: 'CONSULTA_GRAFICO_FATURAMENTO_ENERGIA_LISTADO',
		        payload: resp
			});
        }).catch(ex => {

		});
    };
}

export function modoLista() {
    return [
        showTabs('modoLista'),
        selectTab('modoLista'),
		getListaCliente(),
		getListaUnidade(),
		getListaProduto(),
		listarGraficoConsumoEnergia(),
		listarGraficoFaturamentoEnergia()
    ];
}
