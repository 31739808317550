import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';

const INITIAL_VALUES = { nome: '' };

export function getLista() {
    const request = axios.get(`${consts.API_URL}/cliente`);
    return {
        type: 'CLIENTE_LISTADO',
        payload: request
    };
}

export function getListaEstado() {
    const request = axios.get(`${consts.API_URL}/estado/listarselect`);
    return {
        type: 'CLIENTE_ESTADO_LISTADO',
        payload: request
    };
}

export function getListaCidade(id_estado) {
    const request = axios.get(`${consts.API_URL}/cidade/listarselect?id_estado=${id_estado}`);
    return {
        type: 'CLIENTE_CIDADE_LISTADO',
        payload: request
    };
}

export function getListaUnidade(unidade) {
    const request = axios.get(`${consts.API_URL}/unidade?id_cliente=${unidade.id_cliente}`);
    return {
        type: 'UNIDADE_LISTADA',
        payload: request
    };
}

export function incluir(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/cliente`, registro)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function alterar(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/cliente`, registro)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/cliente?id=${registro.id}`)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoLista());
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function adicionarUnidade(registro) {
	return dispatch => {
        axios.post(`${consts.API_URL}/unidade`, registro)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoUnidadeInclusao({ id_cliente: registro.id_cliente, nome_cliente: registro.nome_cliente }));
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function removerUnidade(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/unidade?id=${registro.id}`)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(modoUnidadeInclusao({ id_cliente: registro.id_cliente, nome_cliente: registro.nome_cliente }));
            })
            .catch(e => {
                if (e.response.status == 400) {
                    e.response.data.forEach(retorno => {
                        toastr.error('Erro',  retorno.msg ? retorno.msg : retorno);
                    });
                } else {
                    toastr.error('Erro', 'Erro ao realizar Operação!!');
                }
            });
    };
}

export function modoLista() {
    return [
        showTabs('modoLista'),
        selectTab('modoLista'),
        getLista(),
        initialize('clienteForm', INITIAL_VALUES)
    ];
}

export function modoInclusao(cliente) {
    return [
        showTabs('modoInclusao'),
        selectTab('modoInclusao'),
        initialize('clienteForm', cliente)
    ];
}

export function modoAlteracao(cliente) {
    return [
        showTabs('modoAlteracao'),
        selectTab('modoAlteracao'),
        initialize('clienteForm', cliente)
    ];
}

export function modoExclusao(cliente) {
    return [
        showTabs('modoExclusao'),
        selectTab('modoExclusao'),
        initialize('clienteForm', cliente)
    ];
}

export function modoUnidadeInclusao(unidade) {
    return [
        showTabs('modoUnidadeInclusao'),
        selectTab('modoUnidadeInclusao'),
		getListaEstado(),
		getListaCidade(0),
		getListaUnidade({ id_cliente: unidade.id_cliente }),
        initialize('clienteForm', unidade)
    ];
}

export function modoUnidadeAlteracao(unidade) {
    return [
        showTabs('modoUnidadeAlteracao'),
        selectTab('modoUnidadeAlteracao'),
		getListaEstado(),
		getListaCidade(unidade.id_estado),
		getListaUnidade({ id_cliente: unidade.id_cliente }),
        initialize('clienteForm', unidade)
    ];
}
