import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import Tabs from '../common/tab/tabs';
import TabsHeader from '../common/tab/tabsHeader';
import TabsContent from '../common/tab/tabsContent';
import TabHeader from '../common/tab/tabHeader';
import TabContent from '../common/tab/tabContent';
import Lista from './ordemServicoLista';
import Form from './ordemServicoForm';
import OrdemServicoPdf from './ordemServicoPdf';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { incluir, alterar, excluir, modoLista, modoInclusao, setFiltro, getLista, modoImpressao } from './ordemServicoActions';

class OrdemServico extends Component {

    componentWillMount() {
        this.props.modoLista();
    }

    render() {
        return (
            <div>
                <ContentHeader title='Ordem de Serviço' small='Cadastro' />
                <Content>
                    <Tabs>
                        <TabsContent>
                            <TabContent id='modoLista'>
                                <button className='btn btn-success' onClick={() => this.props.modoInclusao()}>
                                    <i className='fa fa-plus'> Agendar</i>
                                </button>
								<button type='button' className='btn btn-primary'
									onClick={() => this.props.modoImpressao({ telaAnterior: 'visualizacao' })}><i className='fa fa-file-pdf-o'> Gerar Avulso</i>
								</button>
								<div className='row'>
									<LabelAndInputMask
										label='Data Inicial'
										placeholder='Informe a Data Inicial'
										mask={'99/99/9999'}
										cols='12 4 3'
										value={this.props.filtro.dataInicial}
										onChange={data => {
											this.props.setFiltro({
												...this.props.filtro,
												dataInicial: data.target.value
											});
											this.props.getLista();
										}} />
									<LabelAndInputMask
										label='Data Final'
										placeholder='Informe a Data Final'
										mask={'99/99/9999'}
										cols='12 4 3'
										value={this.props.filtro.dataFinal}
										onChange={data => {
											this.props.setFiltro({
												...this.props.filtro,
												dataFinal: data.target.value
											});
											this.props.getLista();
										}} />
								</div>
                                <Lista />
                            </TabContent>
                            <TabContent id='modoInclusao'>
                                <Form onSubmit={this.props.incluir}
                                    submitLabel='Incluir' submitClass='primary' />
                            </TabContent>
                            <TabContent id='modoAlteracao'>
                                <Form onSubmit={this.props.alterar}
                                    submitLabel='Alterar' submitClass='info' />
                            </TabContent>
                            <TabContent id='modoExclusao'>
                                <Form onSubmit={this.props.excluir}
                                    submitLabel='Excluir' submitClass='danger' readOnly='readOnly' />
                            </TabContent>
							<TabContent id='modoImpressao'>
                                <OrdemServicoPdf />
                            </TabContent>
                        </TabsContent>
                    </Tabs>
                </Content>
            </div>
        )
    }
}
const mapStateToProps = state => ({
	filtro: state.ordemServico.filtro
});
const mapDispatchToProps = dispatch => bindActionCreators({ incluir, alterar, excluir, modoLista, modoInclusao, setFiltro, getLista, modoImpressao }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrdemServico);
