import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import cookie from 'react-cookies'
import { getLista, getListaCliente, getListaUnidade, modoAlteracao, modoExclusao } from './unidadeCargaActions';

import Select from '../common/form/select';

class UnidadeCargaLista extends Component {

    componentWillMount() {
		this.props.getLista(cookie.load('unidadeCargaListaUnidade') || 0);
		this.props.getListaCliente();
		this.props.getListaUnidade(cookie.load('unidadeCargaListaCliente') || 0);
    }

    renderRows() {
        const lista = this.props.lista || [];
        return lista.map(unidadeCarga => (
            <tr key={unidadeCarga.id}>
                <td>{unidadeCarga.nome_cliente}</td>
				<td>{unidadeCarga.nome_unidade}</td>
				<td>{unidadeCarga.nome}</td>
				<td>{unidadeCarga.carga}</td>
				<td>{unidadeCarga.observacao}</td>
                <td>
                    <button className='btn btn-warning' onClick={() => this.props.modoAlteracao(unidadeCarga)}>
                        <i className='fa fa-pencil'></i>
                    </button>
                    <button className='btn btn-danger' onClick={() => this.props.modoExclusao(unidadeCarga)}>
                        <i className='fa fa-trash-o'></i>
                    </button>
                </td>
            </tr>
        ));
    }

    render() {
        return (
            <div>
				<form role='form' onSubmit={() => null}>
					<div className='box-body'>
						<div className='row'>
							<div className='col-sm-12'>
								<Field name='id_cliente' component={Select}
									onChange={(e) => {
										this.props.getLista(0);
										cookie.save('unidadeCargaListaCliente', e.target.value, { path: '/' });
										this.props.getListaUnidade(e.target.value);
									}}
									value={cookie.load('unidadeCargaListaCliente')}
									options={this.props.listaCliente}
									label='Cliente' cols='12 6' placeholder='Selecione o Cliente' />
							</div>
							<div className='col-sm-12'>
								<Field name='id_unidade' component={Select}
									onChange={(e) => {
										cookie.save('unidadeCargaListaUnidade', e.target.value, { path: '/' });
										this.props.getLista(e.target.value);
									}}
									value={cookie.load('unidadeCargaListaUnidade')}
									options={this.props.listaUnidade}
									label='Unidade' cols='12 6' placeholder='Selecione a Unidade' />
							</div>
						</div>
					</div>
				</form>
                <table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
                            <th>Cliente</th>
							<th>Unidade</th>
							<th>Nome</th>
							<th>Carga</th>
							<th>Observação</th>
                            <th className='table-actions'>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

UnidadeCargaLista = reduxForm({form: 'unidadeCargaLista', destroyOnUnmount: false})(UnidadeCargaLista);
const mapStateToProps = state => ({
	lista: state.unidadeCarga.lista,
	listaCliente: state.unidadeCarga.listaCliente,
	listaUnidade: state.unidadeCarga.listaUnidade
});
const mapDispatchToProps = dispatch => bindActionCreators({getLista, getListaCliente, getListaUnidade, modoAlteracao, modoExclusao}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UnidadeCargaLista);
