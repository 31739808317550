import moment from 'moment';

let dataInicial = new Date();
dataInicial.setDate(dataInicial.getDate() - 10);
let dataFinal = new Date();
dataFinal.setDate(dataFinal.getDate() + 30);

const INITIAL_STATE = {
	lista: [],
	listaCliente: [],
	listaUnidade: [],
	configuracao: {},
	filtro: {
		dataInicial: `${moment(dataInicial).format('DD/MM/YYYY')}`,
		dataFinal: moment(dataFinal).format('DD/MM/YYYY'),
		getDataBanco: (data) => `${data.split('/')[2]}-${data.split('/')[1]}-${data.split('/')[0]}`
	},
	ordemServicoImpressao: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'ORDEM_SERVICO_LISTADA':
            return { ...state, lista: action.payload.data };
		case 'ORDEM_SERVICO_FILTRO':
            return { ...state, filtro: action.payload };
		case 'ORDEM_SERVICO_CLIENTE_LISTADO':
            return { ...state, listaCliente: action.payload.data };
		case 'ORDEM_SERVICO_UNIDADE_LISTADA':
            return { ...state, listaUnidade: action.payload.data };
		case 'ORDEM_SERVICO_CONFIGURACAO':
            return { ...state, configuracao: action.payload.data };
		case 'ORDEM_SERVICO_IMPRESSAO_LISTADO':
			return { ...state, ordemServicoImpressao: action.payload.data };
        default:
            return state;
    }
}
