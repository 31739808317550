import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormatarValor from '../common/form/formatarValor';
import { modoAlteracao, modoExclusao, modoDemandaItemAlteracao, adicionarDemandaItem, removerDemandaItem } from './demandaActions';

class DemandaListaDemandaItens extends Component {

    renderRows() {
        const lista = this.props.listaDemandaItem || [];
        return lista.map(demandaItem => {
			let style = {
			}
			if (demandaItem.contratada > 0 && demandaItem.lida > 0) {
				style.backgroundColor = parseFloat(demandaItem.contratada) > parseFloat(demandaItem.lida) ? '#f44336' : '#43a047';
				style.color = '#FFFFFF';
			}

			let styleGeracaoEnergia = {
			}
			if (demandaItem.geracao_energia > 0 && demandaItem.geracao_energia_lida > 0) {
				styleGeracaoEnergia.backgroundColor = parseFloat(demandaItem.geracao_energia) > parseFloat(demandaItem.geracao_energia_lida) ? '#f44336' : '#43a047';
				styleGeracaoEnergia.color = '#FFFFFF';
			}

			let styleRow = {};
			if (demandaItem.aniversario) {
				styleRow = {
					backgroundColor: '#f4ff81'
				};
			}
			return (
	            <tr key={demandaItem.id} style={styleRow}>
					<td>{demandaItem.competencia_formatada.charAt(0).toUpperCase() + demandaItem.competencia_formatada.slice(1)}</td>
					<td align='right'>
						<FormatarValor valor={demandaItem.contratada} displayType={'none'} casas={4} onChange={(event) => {
							this.props.adicionarDemandaItem({ ...demandaItem, contratada: event.target.value });
						}} />
					</td>
					<td align='right'>
						<FormatarValor valor={demandaItem.lida} displayType={'text'} casas={4} />
					</td>
					<td style={style}></td>
					<td align='right'>
						<FormatarValor valor={demandaItem.geracao_energia} displayType={'none'} casas={4} onChange={(event) => {
							this.props.adicionarDemandaItem({ ...demandaItem, geracao_energia: event.target.value });
						}} />
					</td>
					<td align='right'>
						<FormatarValor valor={demandaItem.geracao_energia_lida} displayType={'text'} casas={4} />
					</td>
					<td style={styleGeracaoEnergia}></td>
	            </tr>
        	)
		});
    }

    render() {
        return (
            <div>
                <table className='table table-bordered table-striped dataTable'>
                    <thead>
                        <tr>
							<th>Competência</th>
							<th width={200} style={{ textAlign: 'center' }}>Demanda de Consumo</th>
							<th width={100} style={{ textAlign: 'right' }}>Lida</th>
							<th width={20} style={{ textAlign: 'center' }}>Status</th>
							<th width={200} style={{ textAlign: 'center' }}>Demanda de Geração</th>
							<th width={200} style={{ textAlign: 'right' }}>Lida</th>
							<th width={20} style={{ textAlign: 'center' }}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => ({listaDemandaItem: state.demanda.listaDemandaItem});
const mapDispatchToProps = dispatch => bindActionCreators({ modoAlteracao, modoExclusao, modoDemandaItemAlteracao, adicionarDemandaItem, removerDemandaItem}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DemandaListaDemandaItens);
