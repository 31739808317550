import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioTipoReducer from '../usuarioTipo/usuarioTipoReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import ConfiguracaoReducer from '../configuracao/configuracaoReducer';
import GrandezaReducer from '../grandeza/grandezaReducer';
import TipoProdutoReducer from '../tipoProduto/tipoProdutoReducer';
import ProdutoReducer from '../produto/produtoReducer';
import ClienteReducer from '../cliente/clienteReducer';
import DemandaReducer from '../demanda/demandaReducer';
import FaturaReducer from '../fatura/faturaReducer';
import RelatorioFaturaReducer from '../relatorioFatura/relatorioFaturaReducer';
import RelatorioDemandaReducer from '../relatorioDemanda/relatorioDemandaReducer';
import OrdemServicoReducer from '../ordemServico/ordemServicoReducer';
import ConsultaReducer from '../consulta/consultaReducer';
import UnidadeCargaReducer from '../unidadeCarga/unidadeCargaReducer';
import SimuladorFaturaReducer from '../simuladorFatura/simuladorFaturaReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
    tab: TabReducer,
    usuarioTipo: UsuarioTipoReducer,
    usuario: UsuarioReducer,
	configuracao: ConfiguracaoReducer,
	grandeza: GrandezaReducer,
    tipoProduto: TipoProdutoReducer,
    produto: ProdutoReducer,
    cliente: ClienteReducer,
	demanda: DemandaReducer,
	fatura: FaturaReducer,
	relatorioFatura: RelatorioFaturaReducer,
	relatorioDemanda: RelatorioDemandaReducer,
	ordemServico: OrdemServicoReducer,
    consulta: ConsultaReducer,
    unidadeCarga: UnidadeCargaReducer,
    simuladorFatura: SimuladorFaturaReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
