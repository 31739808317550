import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import UsuarioTipo from '../usuarioTipo/usuarioTipo';
import Usuario from '../usuario/usuario';
import Configuracao from '../configuracao/configuracao';
import Grandeza from '../grandeza/grandeza';
import TipoProduto from '../tipoProduto/tipoProduto';
import Produto from '../produto/produto';
import Cliente from '../cliente/cliente';
import Demanda from '../demanda/demanda';
import Fatura from '../fatura/fatura';
import RelatorioFatura from '../relatorioFatura/relatorioFatura';
import RelatorioDemanda from '../relatorioDemanda/relatorioDemanda';
import OrdemServico from '../ordemServico/ordemServico';
import Consulta from '../consulta/consulta';
import UnidadeCarga from '../unidadeCarga/unidadeCarga';
import SimuladorFatura from '../simuladorFatura/simuladorFatura';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper'>
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route path='/usuarioTipo' component={UsuarioTipo} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/configuracao' component={Configuracao} />
			<Route path='/grandeza' component={Grandeza} />
			<Route path='/tipoProduto' component={TipoProduto} />
			<Route path='/produto' component={Produto} />
			<Route path='/cliente' component={Cliente} />
			<Route path='/demanda' component={Demanda} />
			<Route path='/fatura' component={Fatura} />
			<Route path='/relatorioFatura' component={RelatorioFatura} />
			<Route path='/relatorioDemanda' component={RelatorioDemanda} />
			<Route path='/ordemServico' component={OrdemServico} />
			<Route path='/consulta' component={Consulta} />
			<Route path='/unidadeCarga' component={UnidadeCarga} />
			<Route path='/simuladorFatura' component={SimuladorFatura} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
